import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { EditorState, ContentState, convertFromHTML, convertToRaw, } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import {
    REQUEST_SITE_EMPRESA_ACTION,
    PUT_SITE_EMPRESA_ACTION,
    REQUEST_SITE_EMPRESA_LIST_CONTACTS_ACTION,
    REQUEST_SITE_EMPRESA_LIST_HOURS_ACTION,
    REQUEST_SITE_EMPRESA_LIST_BRANCHES_ACTION,
    POST_SITE_EMPRESA_CONTACT_ACTION,
    POST_SITE_EMPRESA_HOUR_ACTION,
    POST_SITE_EMPRESA_BRANCH_ACTION,
    DELETE_SITE_EMPRESA_CONTACT_ACTION,
    DELETE_SITE_EMPRESA_HOUR_ACTION,
    DELETE_SITE_EMPRESA_BRANCH_ACTION,
    POST_SITE_EMPRESA_LOGO_ACTION,
    REQUEST_SITE_EMPRESA_TEMA_ACTION,
    PUT_SITE_EMPRESA_TEMA_ACTION,
    REQUEST_SITE_EMPRESA_LIST_BANNER_ACTION,
    DELETE_SITE_EMPRESA_LIST_BANNER_ACTION,
    POST_SITE_EMPRESA_LIST_BANNER_ACTION,
    POST_SITE_EMPRESA_BANNER_UPLOAD_ACTION,
    REQUEST_SITE_EMPRESA_DASHBOARD_ACTION,
    PUT_SITE_EMPRESA_CONFIGURATION_ACTION,
    REQUEST_SITE_EMPRESA_CONFIGURATION_ACTION,
    POST_SITE_EMPRESA_METAKEYWORDS_ACTION,
    REQUEST_SITE_EMPRESA_LIST_KEYWORDS_ACTION,
    DELETE_SITE_EMPRESA_KEYWORDS_ACTION,
    REQUEST_SITE_EMPRESA_PLUGINS_ACTION,
    PUT_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION,
    PUT_SITE_EMPRESABANNER_BANNER_ACTION,   
    PUT_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION,
    PUT_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION, 
} from "constants/ActionTypes";
import {
    fnGetCompanyResponse,
    fnUpdateCompanySuccess,
    fnUpdateCompanyError,
    fnGetCompanyContactsSuccess,
    fnGetCompanyHoursSuccess,
    fnGetCompanyBranchesSuccess,
    fnInsertCompanyContactSuccess,
    fnInsertCompanyHourSuccess,
    fnInsertCompanyBranchSuccess,
    fnGetCompanyThemeSuccess,
    fnGetCompanyBannersSuccess,
    fnInsertCompanyLogoSuccess,
    fnInsertCompanyLogoError,
    fnUpdateCompanyThemeSuccess,
    fnUpdateCompanyThemeError,
    fnGetCompanyDashboardResponse,
    fnGetCompanyDashboardError,    
    fnUpdateCompanyConfigurationSuccess,
    fnUpdateCompanyConfigurationError,
    fnGetCompanyConfigurationSuccess, 
    fnInsertCompanyMetakeywordsSuccess,
    fnGetCompanyKeywordsSuccess,
    fnGetCompanyPluginsSuccess,
    fnUpdateCompanyPluginFacebookMessengerSuccess,
    fnUpdateCompanyPluginFacebookMessengerError,
    fnUpdateCompanyPluginGoogleMapsSuccess,
    fnUpdateCompanyPluginGoogleMapsError,
    fnUpdateCompanyPluginWhatsappSuccess,
    fnUpdateCompanyPluginWhatsappError,

} from "actions/site/Empresa";


//const urlApi = 'https://api-sites.bndv.com.br/api/Company/';
 const urlApi = 'https://api-sites-sb.bndv.com.br/api/Company/';

 //const urlApi = 'https://localhost:44316/api/Company/';

const getCompanyRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'obterEmpresa', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Empresa não localizada");
            if (response.status === 400)
                throw new Error("Erro ao recuperar os dados da empresa");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyEmailsContactRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'obterEmpresaEmailsContato', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Empresa não localizada");
            if (response.status === 400)
                throw new Error("Erro ao recuperar os dados de direcionamento de email");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanySocialMidiasRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'obterEmpresaMidiasSociais', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Empresa não localizada");
            if (response.status === 400)
                throw new Error("Erro ao recuperar os dados de mídias sociais");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanySeoRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'obterEmpresaSeo', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Empresa não localizada");
            if (response.status === 400)
                throw new Error("Erro ao recuperar os dados de mídias sociais");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyAboutRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'obterEmpresaSobre', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Empresa não localizada");
            if (response.status === 400)
                throw new Error("Erro ao recuperar os dados de mídias sociais");
        }
        else {
            var objReturn = await response.json();
            const json = {
                idEmpresa: objReturn.idEmpresa,
                missao: convertFromHTML(objReturn.missao).contentBlocks === null
                    ? EditorState.createEmpty()
                    : EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(objReturn.missao))),
                slogan: convertFromHTML(objReturn.slogan).contentBlocks === null
                    ? EditorState.createEmpty()
                    : EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(objReturn.slogan))),
                sobre: convertFromHTML(objReturn.sobre).contentBlocks === null
                    ? EditorState.createEmpty()
                    : EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(objReturn.sobre))),
                valores: convertFromHTML(objReturn.valores).contentBlocks === null
                    ? EditorState.createEmpty()
                    : EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(objReturn.valores))),
                visao: convertFromHTML(objReturn.visao).contentBlocks === null
                    ? EditorState.createEmpty()
                    : EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(objReturn.visao))),
            }
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const updateCompanyRequest = async (token, parameters) => {
    try {
        const response = await fetch(urlApi + 'atualizarEmpresa', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });
        if (!response.ok)
            throw new Error("Erro ao tentar atualizar os dados da empresa");
        else
            return "sucesso";
    }
    catch (err) {
        throw err.message;
    }
}

const updateCompanyAboutRequest = async (token, parameters) => {
    try {
        var dto = {
            idEmpresa: parameters.idEmpresa,
            sobre: draftToHtml(convertToRaw(parameters.sobre.getCurrentContent())),
            missao: draftToHtml(convertToRaw(parameters.missao.getCurrentContent())),
            visao: draftToHtml(convertToRaw(parameters.visao.getCurrentContent())),
            valores: draftToHtml(convertToRaw(parameters.valores.getCurrentContent())),
            slogan: draftToHtml(convertToRaw(parameters.slogan.getCurrentContent())),
        }

        const response = await fetch(urlApi + 'atualizarEmpresaSobre', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(dto)
        });
        if (!response.ok)
            throw new Error("Erro ao tentar atualizar os dados sobre a empresa");
        else
            return "sucesso";
    }
    catch (err) {
        throw err.message;
    }
}

const updateCompanySocialMidiasRequest = async (token, parameters) => {
    try {
        const response = await fetch(urlApi + 'atualizarEmpresaMidiaSocial', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });
        if (!response.ok)
            throw new Error("Erro ao tentar atualizar os dados das mídias sociais da empresa");
        else
            return "sucesso";
    }
    catch (err) {
        throw err.message;
    }
}

const updateCompanySeoRequest = async (token, parameters) => {
    try {
        const response = await fetch(urlApi + 'atualizarEmpresaSeo', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });
        if (!response.ok)
            throw new Error("Erro ao tentar atualizar os dados de SEO da empresa");
        else
            return "sucesso";
    }
    catch (err) {
        throw err.message;
    }
}

const updateCompanyEmailsContactRequest = async (token, parameters) => {
    try {
        const response = await fetch(urlApi + 'atualizarEmpresaEmailContato', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });
        if (!response.ok)
            throw new Error("Erro ao tentar atualizar os dados de redirecionamento de email da empresa");
        else
            return "sucesso";
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyContactsRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'listaEmpresaContato', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Nenhum contato encontrado");
            if (response.status === 400)
                throw new Error("Erro ao recuperar lista de contatos da empresa");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyHoursRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'listaEmpresaHorarioFuncionamento', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Nenhum horário encontrado");
            if (response.status === 400)
                throw new Error("Erro ao recuperar lista de horário de funcionamento da empresa");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyBranchesRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'listaEmpresaFilial', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Nenhuma filial encontrada");
            if (response.status === 400)
                throw new Error("Erro ao recuperar lista de filiais da empresa");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const postCompanyContactRequest = async (token, parameters) => {
    try {
        const response = await fetch(urlApi + 'inserirEmpresaContato', {
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });

        if (!response.ok) {
            throw new Error("Erro ao inserir contato, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const postCompanyHourRequest = async (token, parameters) => {
    try {
        const response = await fetch(urlApi + 'inserirEmpresaHorarioFuncionamento', {
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });

        if (!response.ok) {
            throw new Error("Erro ao inserir horário de funcionamento, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const postCompanyBranchRequest = async (token, parameters) => {
    try {
        const response = await fetch(urlApi + 'inserirEmpresaFilial', {
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });

        if (!response.ok) {
            throw new Error("Erro ao inserir filial, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const deleteCompanyContactRequest = async (token, id) => {
    try {
        const response = await fetch(urlApi + 'removerEmpresaContato?idEmpresaContato=' + id.toString(), {
            method: 'delete',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            throw new Error("Erro ao remover contato, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const deleteCompanyHourRequest = async (token, id) => {
    try {
        const response = await fetch(urlApi + 'removerEmpresaHorarioFuncionamento?idEmpresaHorarioFuncionamento=' + id.toString(), {
            method: 'delete',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            throw new Error("Erro ao remover horário de funcionamento, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const deleteCompanyBranchRequest = async (token, id) => {
    try {
        const response = await fetch(urlApi + 'removerEmpresaFilial?idEmpresaFilial=' + id.toString(), {
            method: 'delete',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            throw new Error("Erro ao remover filial, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const postCompanyLogoRequest = async (token, logo) => {
    var dto = {
        base64Image: logo
    }
    try {
        const response = await fetch(urlApi + 'insereLogo', {
            method: 'post',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(dto)
        });

        if (!response.ok) {
            throw new Error("Erro ao inserir logotipo, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyThemeRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'obterEmpresaTema', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Dados do tema não encontrados");
            if (response.status === 400)
                throw new Error("Erro ao recuperar os dados do tema");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const updateCompanyThemeRequest = async (token, parameters) => {
    try {
        const response = await fetch(urlApi + 'atualizarEmpresaTema', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });

        if (!response.ok) {
            throw new Error("Erro ao atualizar os dados do tema");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyBannersRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'listaBanner', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Nenhum banner encontrado");
            if (response.status === 400)
                throw new Error("Erro ao recuperar lista de banners da empresa");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const deleteCompanyBannerRequest = async (token, idBanner) => {
    try {
        const response = await fetch(urlApi + 'removerEmpresaBanner?idBanner=' + idBanner.toString(), {
            method: 'delete',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            throw new Error("Erro ao remover banner");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const insertCompanyBannerRequest = async (token, idBanner) => {
    try {
        const response = await fetch(urlApi + 'inserirEmpresaBanner?idBanner=' + idBanner.toString(), {
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            throw new Error("Erro ao inserir banner");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const postCompanyBannersUploadRequest = async (token, banner) => {
    var dto = {
        base64Image: banner
    }
    try {
        const response = await fetch(urlApi + 'uploadBanner', {
            method: 'post',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(dto)
        });

        if (!response.ok) {
            throw new Error("Erro ao inserir banner, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyDashboardRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'obterDadosDashboard', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Nenhum banner encontrado");
            if (response.status === 400)
                throw new Error("Erro ao recuperar lista de banners da empresa");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyConfigurationRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'obterEmpresaConfiguracoes', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Configuração não localizada");
            if (response.status === 400)
                throw new Error("Erro ao recuperar as configurações");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}

const getCompanyPluginsRequest = async (token) => { 
    try {
        const response = await fetch(urlApi + 'obterPlugins', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Plugins não localizados");
            if (response.status === 400)
                throw new Error("Erro ao recuperar os plugins");
        }
        else {           
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}


const updateCompanyConfigurationRequest = async (token, parameters) => { 
    try {
        const response = await fetch(urlApi + 'atualizarEmpresaConfiguracoes', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });

        if (!response.ok) {
            throw new Error("Erro ao atualizar as configurações");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}
const updateCompanyPluginFacebookMessengerRequest = async (token, parameters) => { 
    try {
        const response = await fetch(urlApi + 'atualizarEmpresaPluginsFacebookMessenger', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });

        if (!response.ok) {
            throw new Error("Erro ao atualizar o plugin do facebook messenger");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}
const updateCompanyPluginGoogleMapsRequest = async (token, parameters) => { 
    try {
        const response = await fetch(urlApi + 'atualizarEmpresaPluginsGoogleMaps', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });

        if (!response.ok) {
            throw new Error("Erro ao atualizar o plugin do google maps");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}
const updateCompanyPluginWhatsappRequest = async (token, parameters) => { 
    try {
        const response = await fetch(urlApi + 'atualizarEmpresaPluginsWhatsapp', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });

        if (!response.ok) {
            throw new Error("Erro ao atualizar o plugin do whatsapp");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const UpdateCompanyBannerRequest = async (token, idBanner, idMenu,checked) => {
    try{
        var opts = {
            id: idBanner,
            menu: idMenu,
            check: checked
        };

        console.log(JSON.stringify(opts));
    
        const response = await fetch(urlApi + 'atualizarBannerEmpresa', {
            method: 'put',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(opts)
        });

        if (!response.ok) {
            throw new Error("Erro ao atualizar o banner.");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}

const postCompanyMetaKeywordsRequest = async (token, parameters) => {
    try {
        const response = await fetch(urlApi + 'inserirEmpresaMetakeywords', {
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(parameters)
        });

        if (!response.ok) {
            throw new Error("Erro ao inserir Meta Keywords, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}
const getCompanyKeywordsRequest = async (token) => {
    try {
        const response = await fetch(urlApi + 'listaEmpresaKeywords', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Nenhum contato encontrado");
            if (response.status === 400)
                throw new Error("Erro ao recuperar lista de keywords da empresa");
        }
        else {
            const json = await response.json();
            return json;
        }
    }
    catch (err) {
        throw err.message;
    }
}
const deleteCompanyKeywordsRequest = async (token, id) => { 
    try {
        const response = await fetch(urlApi + 'removerEmpresaKeywords?idEmpresaKeywords=' + id.toString(), {
            method: 'delete',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            throw new Error("Erro ao remover keywords, tente novamente");
        }
        else {
            return "sucesso";
        }
    }
    catch (err) {
        throw err.message;
    }
}
function* _getCompany() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const company = yield call(getCompanyRequest, JSON.parse(authUser).token);
        const companyEmailsContact = yield call(getCompanyEmailsContactRequest, JSON.parse(authUser).token);
        const companySocialMidias = yield call(getCompanySocialMidiasRequest, JSON.parse(authUser).token);
        const companySeo = yield call(getCompanySeoRequest, JSON.parse(authUser).token);
        const companyAbout = yield call(getCompanyAboutRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyResponse(
            company,
            companyEmailsContact,
            companySocialMidias,
            companySeo,
            companyAbout));
    } catch (error) {
        console.log(error);
        //yield put(showAuthMessage(error));
    }
}

function* _getCompanyContacts() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const listContacts = yield call(getCompanyContactsRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyContactsSuccess(listContacts));
    }
    catch (error) {
        console.log(error);
    }
}

function* _getCompanyHours() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const listHours = yield call(getCompanyHoursRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyHoursSuccess(listHours));
    }
    catch (error) {
        console.log(error);
    }
}

function* _getCompanyBranches() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const listBrances = yield call(getCompanyBranchesRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyBranchesSuccess(listBrances));
    }
    catch (error) {
        console.log(error);
    }
}

function* _updateCompany() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var company = yield select(state => state.site_empresa.company);
        var companyAbout = yield select(state => state.site_empresa.companyAbout);
        var companySocialMidias = yield select(state => state.site_empresa.companySocialMidias);
        var companySeo = yield select(state => state.site_empresa.companySeo);
        var companyEmailsContact = yield select(state => state.site_empresa.companyEmailsContact);

        yield call(updateCompanyRequest, JSON.parse(authUser).token, company);
        yield call(updateCompanyAboutRequest, JSON.parse(authUser).token, companyAbout);
        yield call(updateCompanySocialMidiasRequest, JSON.parse(authUser).token, companySocialMidias);
        yield call(updateCompanySeoRequest, JSON.parse(authUser).token, companySeo);
        yield call(updateCompanyEmailsContactRequest, JSON.parse(authUser).token, companyEmailsContact);
        yield put(fnUpdateCompanySuccess());
    } catch (error) {
        yield put(fnUpdateCompanyError());
    }
}

function* _insertCompanyContact() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var companyContact = yield select(state => state.site_empresa.companyContacts.post);
        yield call(postCompanyContactRequest, JSON.parse(authUser).token, companyContact);
        yield put(fnInsertCompanyContactSuccess());
        const listContacts = yield call(getCompanyContactsRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyContactsSuccess(listContacts));
    }
    catch (error) {
        console.log(error);
    }
}

function* _insertCompanyHour() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var companyHour = yield select(state => state.site_empresa.companyHours.post);
        yield call(postCompanyHourRequest, JSON.parse(authUser).token, companyHour);
        yield put(fnInsertCompanyHourSuccess());
        const listHours = yield call(getCompanyHoursRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyHoursSuccess(listHours));
    }
    catch (error) {
        console.log(error);
    }
}

function* _insertCompanyBranch() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var companyBranch = yield select(state => state.site_empresa.companyBranches.post);
        yield call(postCompanyBranchRequest, JSON.parse(authUser).token, companyBranch);
        yield put(fnInsertCompanyBranchSuccess());
        const listBranches = yield call(getCompanyBranchesRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyBranchesSuccess(listBranches));
    }
    catch (error) {
        console.log(error);
    }
}

function* _deleteCompanyContact() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const id = yield select(state => state.site_empresa.companyContacts.deleteId);
        yield call(deleteCompanyContactRequest, JSON.parse(authUser).token, id);
        const listContacts = yield call(getCompanyContactsRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyContactsSuccess(listContacts));
    }
    catch (error) {
        console.log(error);
    }
}

function* _deleteCompanyHour() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const id = yield select(state => state.site_empresa.companyHours.deleteId);
        yield call(deleteCompanyHourRequest, JSON.parse(authUser).token, id);
        const listHours = yield call(getCompanyHoursRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyHoursSuccess(listHours));
    }
    catch (error) {
        console.log(error);
    }
}

function* _deleteCompanyBranch() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const id = yield select(state => state.site_empresa.companyBranches.deleteId);
        yield call(deleteCompanyBranchRequest, JSON.parse(authUser).token, id);
        const listBranches = yield call(getCompanyBranchesRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyBranchesSuccess(listBranches));
    }
    catch (error) {
        console.log(error);
    }
}

function* _postCompanyLogo() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const logo = yield select(state => state.site_empresa.companyLogo.base64Image);
        yield call(postCompanyLogoRequest, JSON.parse(authUser).token, logo);
        yield put(fnInsertCompanyLogoSuccess());
    }
    catch (error) {
        yield put(fnInsertCompanyLogoError());
    }
}

function* _getCompanyTheme() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const companyTheme = yield call(getCompanyThemeRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyThemeSuccess(companyTheme));
    } catch (error) {
        //yield put(showAuthMessage(error));
    }
}

function* _updateCompanyTheme() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var companyTheme = yield select(state => state.site_empresa.companyTheme);
        yield call(updateCompanyThemeRequest, JSON.parse(authUser).token, companyTheme);
        yield put(fnUpdateCompanyThemeSuccess());
    } catch (error) {
        yield put(fnUpdateCompanyThemeError());
    }
}

function* _getCompanyBanners() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const listBanners = yield call(getCompanyBannersRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyBannersSuccess(listBanners));
    }
    catch (error) {
        console.log(error);
    }
}

function* _deleteCompanyBanner() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const id = yield select(state => state.site_empresa.companyBanners.deleteId);
        yield call(deleteCompanyBannerRequest, JSON.parse(authUser).token, id);
        const listBanners = yield call(getCompanyBannersRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyBannersSuccess(listBanners));
    }
    catch (error) {
        console.log(error);
    }
}

function* _insertCompanyBanner() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const id = yield select(state => state.site_empresa.companyBanners.insertId);
        yield call(insertCompanyBannerRequest, JSON.parse(authUser).token, id);
        const listBanners = yield call(getCompanyBannersRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyBannersSuccess(listBanners));
    }
    catch (error) {
        console.log(error);
    }
}

function* _postCompanyBannersUpload() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const banner = yield select(state => state.site_empresa.companyBanners.base64Image);
        yield call(postCompanyBannersUploadRequest, JSON.parse(authUser).token, banner);
        const listBanners = yield call(getCompanyBannersRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyBannersSuccess(listBanners));
    }
    catch (error) {
        console.log(error);
    }
}

function* _getCompanyDashboard() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var dashboard = yield call(getCompanyDashboardRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyDashboardResponse(dashboard));
    }
    catch (error) {
        yield put(fnGetCompanyDashboardError(error.toString()));
    }
}

function* _getCompanyConfiguration() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const companyConfiguration = yield call(getCompanyConfigurationRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyConfigurationSuccess(companyConfiguration));
    } catch (error) {
        
    }
}
function* _getCompanyPlugins() { 
    try {
        const authUser = yield select(state => state.auth.authUser);
        const companyPlugins = yield call(getCompanyPluginsRequest, JSON.parse(authUser).token);        
        yield put(fnGetCompanyPluginsSuccess(companyPlugins));
    } catch (error) {
        
    }
}
function* _updateCompanyConfiguration() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var companyConfiguration = yield select(state => state.site_empresa.companyConfiguration);
        yield call(updateCompanyConfigurationRequest, JSON.parse(authUser).token, companyConfiguration);
        yield put(fnUpdateCompanyConfigurationSuccess());
    } catch (error) {
        yield put(fnUpdateCompanyConfigurationError());
    }
}
function* _companyPluginFacebookMessenger() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var companyPluginsFacebookMessenger = yield select(state => state.site_empresa.companyPlugins.facebookMessenger);
        yield call(updateCompanyPluginFacebookMessengerRequest, JSON.parse(authUser).token, companyPluginsFacebookMessenger);
        yield put(fnUpdateCompanyPluginFacebookMessengerSuccess());
    } catch (error) {
        yield put(fnUpdateCompanyPluginFacebookMessengerError());
    }
}
function* _companyPluginGoogleMaps() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var companyPluginsGoogleMaps = yield select(state => state.site_empresa.companyPlugins.googleMaps);
        yield call(updateCompanyPluginGoogleMapsRequest, JSON.parse(authUser).token, companyPluginsGoogleMaps);
        yield put(fnUpdateCompanyPluginGoogleMapsSuccess());
    } catch (error) {
        yield put(fnUpdateCompanyPluginGoogleMapsError());
    }
}
function* _companyPluginWhatsapp() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var companyPluginsWhatsapp = yield select(state => state.site_empresa.companyPlugins.whatsapp);
        yield call(updateCompanyPluginWhatsappRequest, JSON.parse(authUser).token, companyPluginsWhatsapp);
        yield put(fnUpdateCompanyPluginWhatsappSuccess());
    } catch (error) {
        yield put(fnUpdateCompanyPluginWhatsappError());
    }
}
function* _updateCompanyBanner({payload}) {
    try {      
        const authUser = yield select(state => state.auth.authUser);
        const {idBanner, idMenu,check} = payload;        
        yield call(UpdateCompanyBannerRequest, JSON.parse(authUser).token, idBanner, idMenu, check);
       
    } catch (error) {
       
    }
}

function* _insertCompanyMetaKeywords() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        var companyKeywords = yield select(state => state.site_empresa.companyKeywords.post);
        yield call(postCompanyMetaKeywordsRequest, JSON.parse(authUser).token, companyKeywords);
        yield put(fnInsertCompanyMetakeywordsSuccess());
        const listKeywords = yield call(getCompanyKeywordsRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyKeywordsSuccess(listKeywords));
    }
    catch (error) {
        console.log(error);
    }
}
function* _getCompanyKeywords() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const listKeywords = yield call(getCompanyKeywordsRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyKeywordsSuccess(listKeywords));
    }
    catch (error) {
        console.log(error);
    }
}
function* _deleteCompanyKeywords() {
    try {
        const authUser = yield select(state => state.auth.authUser);
        const id = yield select(state => state.site_empresa.companyKeywords.deleteId);
        yield call(deleteCompanyKeywordsRequest, JSON.parse(authUser).token, id);
        const listKeywords = yield call(getCompanyKeywordsRequest, JSON.parse(authUser).token);
        yield put(fnGetCompanyKeywordsSuccess(listKeywords));
    }
    catch (error) {
        console.log(error);
    }
}

export function* getCompany() {
    yield takeEvery(REQUEST_SITE_EMPRESA_ACTION, _getCompany);
}

export function* getCompanyContacts() {
    yield takeEvery(REQUEST_SITE_EMPRESA_LIST_CONTACTS_ACTION, _getCompanyContacts);
}

export function* getCompanyBranches() {
    yield takeEvery(REQUEST_SITE_EMPRESA_LIST_BRANCHES_ACTION, _getCompanyBranches);
}

export function* getCompanyHours() {
    yield takeEvery(REQUEST_SITE_EMPRESA_LIST_HOURS_ACTION, _getCompanyHours);
}

export function* updateCompany() {
    yield takeEvery(PUT_SITE_EMPRESA_ACTION, _updateCompany);
}

export function* insertCompanyContact() {
    yield takeEvery(POST_SITE_EMPRESA_CONTACT_ACTION, _insertCompanyContact);
}

export function* insertCompanyHour() {
    yield takeEvery(POST_SITE_EMPRESA_HOUR_ACTION, _insertCompanyHour);
}

export function* insertCompanyBranch() {
    yield takeEvery(POST_SITE_EMPRESA_BRANCH_ACTION, _insertCompanyBranch);
}

export function* deleteCompanyContact() {
    yield takeEvery(DELETE_SITE_EMPRESA_CONTACT_ACTION, _deleteCompanyContact);
}

export function* deleteCompanyHour() {
    yield takeEvery(DELETE_SITE_EMPRESA_HOUR_ACTION, _deleteCompanyHour);
}

export function* deleteCompanyBranch() {
    yield takeEvery(DELETE_SITE_EMPRESA_BRANCH_ACTION, _deleteCompanyBranch);
}

export function* postCompanyLogo() {
    yield takeEvery(POST_SITE_EMPRESA_LOGO_ACTION, _postCompanyLogo);
}

export function* getCompanyTheme() {
    yield takeEvery(REQUEST_SITE_EMPRESA_TEMA_ACTION, _getCompanyTheme);
}

export function* updateCompanyTheme() {
    yield takeEvery(PUT_SITE_EMPRESA_TEMA_ACTION, _updateCompanyTheme);
}

export function* getCompanyBanners() {
    yield takeEvery(REQUEST_SITE_EMPRESA_LIST_BANNER_ACTION, _getCompanyBanners);
}

export function* deleteCompanyBanner() {
    yield takeEvery(DELETE_SITE_EMPRESA_LIST_BANNER_ACTION, _deleteCompanyBanner);
}

export function* insertCompanyBanner() {
    yield takeEvery(POST_SITE_EMPRESA_LIST_BANNER_ACTION, _insertCompanyBanner);
}

export function* postCompanyBannersUpload() {
    yield takeEvery(POST_SITE_EMPRESA_BANNER_UPLOAD_ACTION, _postCompanyBannersUpload);
}

export function* getCompanyDashboard() {
    yield takeEvery(REQUEST_SITE_EMPRESA_DASHBOARD_ACTION, _getCompanyDashboard);
}
export function* getCompanyConfiguration() {
    yield takeEvery(REQUEST_SITE_EMPRESA_CONFIGURATION_ACTION, _getCompanyConfiguration);
}

export function* updateCompanyConfiguration() {
    yield takeEvery(PUT_SITE_EMPRESA_CONFIGURATION_ACTION, _updateCompanyConfiguration);
}
export function* insertCompanyMetaKeywords() {
    yield takeEvery(POST_SITE_EMPRESA_METAKEYWORDS_ACTION, _insertCompanyMetaKeywords);
}
export function* getCompanyKeywords() {
    yield takeEvery(REQUEST_SITE_EMPRESA_LIST_KEYWORDS_ACTION, _getCompanyKeywords);
}
export function* deleteCompanyKeywords() {
    yield takeEvery(DELETE_SITE_EMPRESA_KEYWORDS_ACTION, _deleteCompanyKeywords);
}
export function* getCompanyPlugins() {
    yield takeEvery(REQUEST_SITE_EMPRESA_PLUGINS_ACTION, _getCompanyPlugins);
}
export function* updateCompanyPluginFacebookMessenger() {
    yield takeEvery(PUT_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION, _companyPluginFacebookMessenger);
}
export function* updateCompanyPluginGoogleMaps() {
    yield takeEvery(PUT_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION, _companyPluginGoogleMaps);
}
export function* updateCompanyPluginWhatsapp() {
    yield takeEvery(PUT_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION, _companyPluginWhatsapp);
}
export function* updateCompanyBanner() {
    yield takeEvery(PUT_SITE_EMPRESABANNER_BANNER_ACTION, _updateCompanyBanner);
}

export default function* rootSaga() {
    yield all([
        fork(getCompany),
        fork(updateCompany),
        fork(getCompanyContacts),
        fork(getCompanyHours),
        fork(getCompanyBranches),
        fork(insertCompanyContact),
        fork(insertCompanyHour),
        fork(insertCompanyBranch),
        fork(deleteCompanyContact),
        fork(deleteCompanyHour),
        fork(deleteCompanyBranch),
        fork(postCompanyLogo),
        fork(getCompanyTheme),
        fork(updateCompanyTheme),
        fork(getCompanyBanners),
        fork(deleteCompanyBanner),
        fork(insertCompanyBanner),
        fork(postCompanyBannersUpload),
        fork(getCompanyDashboard),
        fork(updateCompanyConfiguration),
        fork(getCompanyConfiguration),
        fork(insertCompanyMetaKeywords),
        fork(getCompanyKeywords),
        fork(deleteCompanyKeywords),
        fork(getCompanyPlugins),
        fork(updateCompanyPluginFacebookMessenger),
        fork(updateCompanyBanner),
        fork(updateCompanyPluginGoogleMaps),
        fork(updateCompanyPluginWhatsapp),
    ]);
}