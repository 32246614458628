// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';
export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';
export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

// Global const
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const SHOW_NOTIFICATION = 'show_notification';
export const HIDE_NOTIFICATION = 'hide_notification';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';

// Site/Empresa Const
export const REQUEST_SITE_EMPRESA_ACTION = 'REQUEST_SITE_EMPRESA_ACTION';
export const PUT_SITE_EMPRESA_ACTION = 'PUT_SITE_EMPRESA_ACTION';
export const RESPONSE_SITE_EMPRESA_ACTION = 'RESPONSE_SITE_EMPRESA_ACTION';
export const SUCCESS_SITE_EMPRESA_ACTION = 'SUCCESS_SITE_EMPRESA_ACTION';
export const ERROR_SITE_EMPRESA_ACTION = 'ERROR_SITE_EMPRESA_ACTION';
export const SITE_EMPRESA_SET_DOMAIN_ACTION = 'SITE_EMPRESA_SET_DOMAIN_ACTION';
export const SITE_EMPRESA_SET_FANTASY_NAME_ACTION = 'SITE_EMPRESA_SET_FANTASY_NAME_ACTION';
export const SITE_EMPRESA_SET_PHONE_ACTION = 'SITE_EMPRESA_SET_PHONE_ACTION';
export const SITE_EMPRESA_SET_EMAIL_DEFAULT_ACTION = 'SITE_EMPRESA_SET_EMAIL_DEFAULT_ACTION';
export const SITE_EMPRESA_SET_ADDRESS_ACTION = 'SITE_EMPRESA_SET_ADDRESS_ACTION';
export const SITE_EMPRESA_SET_CITY_ACTION = 'SITE_EMPRESA_SET_CITY_ACTION';
export const SITE_EMPRESA_SET_UF_ACTION = 'SITE_EMPRESA_SET_UF_ACTION';
export const SITE_EMPRESA_SET_POSTAL_CODE_ACTION = 'SITE_EMPRESA_SET_POSTAL_CODE_ACTION';
export const SITE_EMPRESA_SET_EMAIL_CONTACT_CONTACT = 'SITE_EMPRESA_SET_EMAIL_CONTACT_CONTACT';
export const SITE_EMPRESA_SET_EMAIL_CONTACT_PROPOSAL = 'SITE_EMPRESA_SET_EMAIL_CONTACT_PROPOSAL';
export const SITE_EMPRESA_SET_EMAIL_CONTACT_FINANCING = 'SITE_EMPRESA_SET_EMAIL_CONTACT_FINANCING';
export const SITE_EMPRESA_SET_EMAIL_CONTACT_SAFE = 'SITE_EMPRESA_SET_EMAIL_CONTACT_SAFE';
export const SITE_EMPRESA_SET_ABOUT_ABOUT = 'SITE_EMPRESA_SET_ABOUT_ABOUT';
export const SITE_EMPRESA_SET_ABOUT_MISSION = 'SITE_EMPRESA_SET_ABOUT_MISSION';
export const SITE_EMPRESA_SET_ABOUT_VISION = 'SITE_EMPRESA_SET_ABOUT_VISION';
export const SITE_EMPRESA_SET_ABOUT_VALUES = 'SITE_EMPRESA_SET_ABOUT_VALUES';
export const SITE_EMPRESA_SET_SOCIAL_MIDIAS_FACEBOOK = 'SITE_EMPRESA_SET_SOCIAL_MIDIAS_FACEBOOK';
export const SITE_EMPRESA_SET_SOCIAL_MIDIAS_INSTAGRAM = 'SITE_EMPRESA_SET_SOCIAL_MIDIAS_INSTAGRAM';
export const SITE_EMPRESA_SET_SOCIAL_MIDIAS_YOUTUBE = 'SITE_EMPRESA_SET_SOCIAL_MIDIAS_YOUTUBE';
export const SITE_EMPRESA_SET_SEO_GOOGLE_ANALYTICS_ID = 'SITE_EMPRESA_SET_SEO_GOOGLE_ANALYTICS_ID';
export const SITE_EMPRESA_SET_SEO_FACEBOOK_ID = 'SITE_EMPRESA_SET_SEO_FACEBOOK_ID';
export const SITE_EMPRESA_SET_SEO_FACEBOOK_PIXEL_ID = 'SITE_EMPRESA_SET_SEO_FACEBOOK_PIXEL_ID';
export const REQUEST_SITE_EMPRESA_LIST_CONTACTS_ACTION = 'REQUEST_SITE_EMPRESA_LIST_CONTACTS_ACTION';
export const RESPONSE_SITE_EMPRESA_LIST_CONTACTS_ACTION = 'RESPONSE_SITE_EMPRESA_LIST_CONTACTS_ACTION';
export const ERROR_SITE_EMPRESA_LIST_CONTACTS_ACTION = 'ERROR_SITE_EMPRESA_LIST_CONTACTS_ACTION';
export const REQUEST_SITE_EMPRESA_LIST_HOURS_ACTION = 'REQUEST_SITE_EMPRESA_LIST_HOURS_ACTION';
export const RESPONSE_SITE_EMPRESA_LIST_HOURS_ACTION = 'RESPONSE_SITE_EMPRESA_LIST_HOURS_ACTION';
export const ERROR_SITE_EMPRESA_LIST_HOURS_ACTION = 'ERROR_SITE_EMPRESA_LIST_HOURS_ACTION';
export const REQUEST_SITE_EMPRESA_LIST_BRANCHES_ACTION = 'REQUEST_SITE_EMPRESA_LIST_BRANCHES_ACTION';
export const RESPONSE_SITE_EMPRESA_LIST_BRANCHES_ACTION = 'RESPONSE_SITE_EMPRESA_LIST_BRANCHES_ACTION';
export const ERROR_SITE_EMPRESA_LIST_BRANCHES_ACTION = 'ERROR_SITE_EMPRESA_LIST_BRANCHES_ACTION';
export const SITE_EMPRESA_OPEN_MODAL_CONTACTS = 'SITE_EMPRESA_OPEN_MODAL_CONTACTS';
export const SITE_EMPRESA_CLOSE_MODAL_CONTACTS = 'SITE_EMPRESA_CLOSE_MODAL_CONTACTS';
export const SITE_EMPRESA_SET_CONTACT_TYPE_CONTACT = 'SITE_EMPRESA_SET_CONTACT_TYPE_CONTACT';
export const SITE_EMPRESA_SET_CONTACT_TITLE = 'SITE_EMPRESA_SET_CONTACT_TITLE';
export const SITE_EMPRESA_SET_CONTACT_VALUE = 'SITE_EMPRESA_SET_CONTACT_VALUE'; 
export const POST_SITE_EMPRESA_CONTACT_ACTION = 'POST_SITE_EMPRESA_CONTACT_ACTION';
export const SUCCESS_SITE_EMPRESA_CONTACT_ACTION = 'SUCCESS_SITE_EMPRESA_CONTACT_ACTION';
export const SITE_EMPRESA_OPEN_MODAL_HOURS = 'SITE_EMPRESA_OPEN_MODAL_HOURS';
export const SITE_EMPRESA_CLOSE_MODAL_HOURS = 'SITE_EMPRESA_CLOSE_MODAL_HOURS';
export const SITE_EMPRESA_SET_HOUR_NAME = 'SITE_EMPRESA_SET_HOUR_NAME';
export const SITE_EMPRESA_SET_HOUR_OPEN = 'SITE_EMPRESA_SET_HOUR_OPEN';
export const SITE_EMPRESA_SET_HOUR_CLOSE = 'SITE_EMPRESA_SET_HOUR_CLOSE';
export const POST_SITE_EMPRESA_HOUR_ACTION = 'POST_SITE_EMPRESA_HOUR_ACTION';
export const SUCCESS_SITE_EMPRESA_HOUR_ACTION = 'SUCCESS_SITE_EMPRESA_HOUR_ACTION';
export const SITE_EMPRESA_OPEN_MODAL_BRANCHES = 'SITE_EMPRESA_OPEN_MODAL_BRANCHES';
export const SITE_EMPRESA_CLOSE_MODAL_BRANCHES = 'SITE_EMPRESA_CLOSE_MODAL_BRANCHES';
export const SITE_EMPRESA_SET_BRANCHES_NAME = 'SITE_EMPRESA_SET_BRANCHES_NAME';
export const SITE_EMPRESA_SET_BRANCHES_ADDRESS = 'SITE_EMPRESA_SET_BRANCHES_ADDRESS';
export const SITE_EMPRESA_SET_BRANCHES_POSTAL_CODE = 'SITE_EMPRESA_SET_BRANCHES_POSTAL_CODE';
export const SITE_EMPRESA_SET_BRANCHES_CITY = 'SITE_EMPRESA_SET_BRANCHES_CITY';
export const SITE_EMPRESA_SET_BRANCHES_UF = 'SITE_EMPRESA_SET_BRANCHES_UF';
export const SITE_EMPRESA_SET_BRANCHES_EMAIL = 'SITE_EMPRESA_SET_BRANCHES_EMAIL';
export const SITE_EMPRESA_SET_BRANCHES_PHONE = 'SITE_EMPRESA_SET_BRANCHES_PHONE';
export const POST_SITE_EMPRESA_BRANCH_ACTION = 'POST_SITE_EMPRESA_BRANCH_ACTION';
export const SUCCESS_SITE_EMPRESA_BRANCH_ACTION = 'SUCCESS_SITE_EMPRESA_BRANCH_ACTION';
export const DELETE_SITE_EMPRESA_CONTACT_ACTION = 'DELETE_SITE_EMPRESA_CONTACT_ACTION';
export const DELETE_SITE_EMPRESA_HOUR_ACTION = 'DELETE_SITE_EMPRESA_HOUR_ACTION';
export const DELETE_SITE_EMPRESA_BRANCH_ACTION = 'DELETE_SITE_EMPRESA_BRANCH_ACTION';
export const POST_SITE_EMPRESA_LOGO_ACTION = 'POST_SITE_EMPRESA_LOGO_ACTION';
export const SUCCESS_SITE_EMPRESA_LOGO_ACTION = 'SUCCESS_SITE_EMPRESA_LOGO_ACTION';
export const ERROR_SITE_EMPRESA_LOGO_ACTION = 'ERROR_SITE_EMPRESA_LOGO_ACTION';
export const REQUEST_SITE_EMPRESA_TEMA_ACTION = 'REQUEST_SITE_EMPRESA_TEMA_ACTION';
export const PUT_SITE_EMPRESA_TEMA_ACTION = 'PUT_SITE_EMPRESA_TEMA_ACTION';
export const RESPONSE_SITE_EMPRESA_TEMA_ACTION = 'RESPONSE_SITE_EMPRESA_TEMA_ACTION';
export const SUCCESS_SITE_EMPRESA_TEMA_ACTION = 'SUCCESS_SITE_EMPRESA_TEMA_ACTION';
export const ERROR_SITE_EMPRESA_TEMA_ACTION = 'ERROR_SITE_EMPRESA_TEMA_ACTION';
export const SITE_EMPRESA_THEME_SET_FONT_TYPE = 'SITE_EMPRESA_THEME_SET_FONT_TYPE';
export const SITE_EMPRESA_THEME_SET_BACKGROUND_COLOR = 'SITE_EMPRESA_THEME_SET_BACKGROUND_COLOR';
export const SITE_EMPRESA_THEME_SET_PRIMARY_COLOR = 'SITE_EMPRESA_THEME_SET_PRIMARY_COLOR';
export const SITE_EMPRESA_THEME_SET_SECUNDARY_COLOR = 'SITE_EMPRESA_THEME_SET_SECUNDARY_COLOR';
export const SITE_EMPRESA_THEME_SET_THEME = 'SITE_EMPRESA_THEME_SET_THEME';
export const REQUEST_SITE_EMPRESA_LIST_BANNER_ACTION = 'REQUEST_SITE_EMPRESA_LIST_BANNER_ACTION';
export const RESPONSE_SITE_EMPRESA_LIST_BANNER_ACTION = 'RESPONSE_SITE_EMPRESA_LIST_BANNER_ACTION';
export const ERROR_SITE_EMPRESA_LIST_BANNER_ACTION = 'ERROR_SITE_EMPRESA_LIST_BANNER_ACTION';
export const DELETE_SITE_EMPRESA_LIST_BANNER_ACTION = 'DELETE_SITE_EMPRESA_LIST_BANNER_ACTION';
export const POST_SITE_EMPRESA_LIST_BANNER_ACTION = 'POST_SITE_EMPRESA_LIST_BANNER_ACTION';
export const SITE_EMPRESA_BANNER_SET_DELETE_ID = 'SITE_EMPRESA_BANNER_SET_DELETE_ID';
export const SITE_EMPRESA_BANNER_SET_INSERT_ID = 'SITE_EMPRESA_BANNER_SET_INSERT_ID';
export const POST_SITE_EMPRESA_BANNER_UPLOAD_ACTION = 'POST_SITE_EMPRESA_BANNER_UPLOAD_ACTION';
export const REQUEST_SITE_EMPRESA_DASHBOARD_ACTION = 'REQUEST_SITE_EMPRESA_DASHBOARD_ACTION';
export const RESPONSE_SITE_EMPRESA_DASHBOARD_ACTION = 'RESPONSE_SITE_EMPRESA_DASHBOARD_ACTION';
export const ERROR_SITE_EMPRESA_DASHBOARD_ACTION = 'ERROR_SITE_EMPRESA_DASHBOARD_ACTION';
export const REQUEST_SITE_EMPRESA_LIST_KEYWORDS_ACTION = 'REQUEST_SITE_EMPRESA_LIST_KEYWORDS_ACTION';
export const SITE_EMPRESA_OPEN_MODAL_KEYWORDS = 'SITE_EMPRESA_OPEN_MODAL_KEYWORDS';
export const SITE_EMPRESA_CLOSE_MODAL_KEYWORDS = 'SITE_EMPRESA_CLOSE_MODAL_KEYWORDS';
export const SITE_EMPRESA_SET_KEYWORDS_TYPE_SCREEN = 'SITE_EMPRESA_SET_KEYWORDS_TYPE_SCREEN';
export const SITE_EMPRESA_SET_KEYWORDS_TITLE = 'SITE_EMPRESA_SET_KEYWORDS_TITLE';
export const SITE_EMPRESA_SET_KEYWORDS_METAKEYWORDS = 'SITE_EMPRESA_SET_KEYWORDS_METAKEYWORDS';
export const SITE_EMPRESA_SET_KEYWORDS_METADESCRIPTION = 'SITE_EMPRESA_SET_KEYWORDS_METADESCRIPTION';
export const PUT_SITE_EMPRESA_CONFIGURATION_ACTION = 'PUT_SITE_EMPRESA_CONFIGURATION_ACTION';
export const SUCCESS_SITE_EMPRESA_CONFIGURACAO_ACTION = 'SUCCESS_SITE_EMPRESA_CONFIGURACAO_ACTION';
export const ERROR_SITE_EMPRESA_CONFIGURACAO_ACTION = 'ERROR_SITE_EMPRESA_CONFIGURACAO_ACTION';
export const REQUEST_SITE_EMPRESA_CONFIGURATION_ACTION = 'REQUEST_SITE_EMPRESA_CONFIGURATION_ACTION';
export const RESPONSE_SITE_EMPRESA_CONFIGURATION_ACTION = 'RESPONSE_SITE_EMPRESA_CONFIGURATION_ACTION';
export const SITE_EMPRESA_SET_CONFIGURATION_ORDERTYPE = 'SITE_EMPRESA_SET_CONFIGURATION_ORDERTYPE';
export const SITE_EMPRESA_SET_CONFIGURATION_INSURANCE = 'SITE_EMPRESA_SET_CONFIGURATION_INSURANCE';
export const SITE_EMPRESA_SET_CONFIGURATION_FINANCING = 'SITE_EMPRESA_SET_CONFIGURATION_FINANCING';
export const SITE_EMPRESA_SET_CONFIGURATION_GOOGLEVERIFICATIONCODE = 'SITE_EMPRESA_SET_CONFIGURATION_GOOGLEVERIFICATIONCODE';
export const POST_SITE_EMPRESA_METAKEYWORDS_ACTION = 'POST_SITE_EMPRESA_METAKEYWORDS_ACTION';
export const SUCCESS_SITE_EMPRESA_METAKEYWORDS_ACTION = 'SUCCESS_SITE_EMPRESA_METAKEYWORDS_ACTION';
export const RESPONSE_SITE_EMPRESA_LIST_KEYWORDS_ACTION = 'RESPONSE_SITE_EMPRESA_LIST_KEYWORDS_ACTION';
export const DELETE_SITE_EMPRESA_KEYWORDS_ACTION = 'DELETE_SITE_EMPRESA_KEYWORDS_ACTION';
export const SITE_EMPRESA_CLOSE_MODAL_KEYWORDS_DELETECONFIRM = 'SITE_EMPRESA_CLOSE_MODAL_KEYWORDS_DELETECONFIRM';
export const SITE_EMPRESA_OPEN_MODAL_KEYWORDS_DELETECONFIRM = 'SITE_EMPRESA_OPEN_MODAL_KEYWORDS_DELETECONFIRM';
export const SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_COLOR_THEME = 'SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_COLOR_THEME';
export const REQUEST_SITE_EMPRESA_PLUGINS_ACTION = 'REQUEST_SITE_EMPRESA_PLUGINS_ACTION';
export const RESPONSE_SITE_EMPRESA_PLUGINS_ACTION = 'RESPONSE_SITE_EMPRESA_PLUGINS_ACTION';
export const SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_OUT_GREETING = 'SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_OUT_GREETING';
export const SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_IN_GREETING = 'SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_IN_GREETING';
export const SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_PAGE_ID = 'SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_PAGE_ID';
export const SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_ATIVO = 'SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_ATIVO';
export const PUT_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION = 'PUT_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION';
export const SUCCESS_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION = 'SUCCESS_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION';
export const ERROR_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION = 'ERROR_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION';
export const PUT_SITE_EMPRESABANNER_BANNER_ACTION = 'PUT_SITE_EMPRESABANNER_BANNER_ACTION';
export const SITE_EMPRESA_SET_CONFIGURATION_FACEBOOKDOMAINVERIFICATIONCODE = 'SITE_EMPRESA_SET_CONFIGURATION_FACEBOOKDOMAINVERIFICATIONCODE';
export const SITE_EMPRESA_SET_CONFIGURATION_VEHICLEPURCHASE = 'SITE_EMPRESA_SET_CONFIGURATION_VEHICLEPURCHASE';
export const SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ATIVO = 'SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ATIVO';
export const SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ENDERECO = 'SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ENDERECO';
export const SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_NUMERO = 'SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_NUMERO';
export const SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_COMPLEMENTO = 'SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_COMPLEMENTO';
export const SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_BAIRRO = 'SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_BAIRRO';
export const SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CIDADE = 'SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CIDADE';
export const SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_UF = 'SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_UF';
export const SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CEP = 'SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CEP';
export const PUT_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION = 'PUT_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION';
export const SUCCESS_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION = 'SUCCESS_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION';
export const ERROR_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION = 'ERROR_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION';
export const SITE_EMPRESA_SET_EMAIL_CONTACT_VEHICLEPURCHASE = 'SITE_EMPRESA_SET_EMAIL_CONTACT_VEHICLEPURCHASE';
export const SITE_EMPRESA_SET_CONFIGURATION_TAGBIPCARROS = 'SITE_EMPRESA_SET_CONFIGURATION_TAGBIPCARROS';
export const SITE_EMPRESA_SET_CONFIGURATION_GOOGLETAGMANAGER = 'SITE_EMPRESA_SET_CONFIGURATION_GOOGLETAGMANAGER';
export const SITE_EMPRESA_PLUGINS_SET_WHATSAPP_ATIVO = 'SITE_EMPRESA_PLUGINS_SET_WHATSAPP_ATIVO';
export const SITE_EMPRESA_PLUGINS_SET_WHATSAPP_POSICIONAMENTO = 'SITE_EMPRESA_PLUGINS_SET_WHATSAPP_POSICIONAMENTO';
export const SITE_EMPRESA_PLUGINS_SET_WHATSAPP_NUMERO = 'SITE_EMPRESA_PLUGINS_SET_WHATSAPP_NUMERO';
export const PUT_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION = 'PUT_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION';
export const SUCCESS_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION = 'SUCCESS_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION';
export const ERROR_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION = 'ERROR_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION';
