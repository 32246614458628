import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
} from 'actions/Auth';

class SignIn extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      email: '',
      password: '',
      cnpj:'',
      showPassword: false,
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleSubmit(e) {
    e.preventDefault();
    const { email, password,cnpj } = this.state;
    this.props.showAuthLoader();
    this.props.userSignIn({ email, password,cnpj });
  }

  render() {
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <p style={{ color: 'white', fontSize: '36px', width: '100%', textAlign: 'center' }}>BNDV</p>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Faça seu Login</h1>
            </div>

            <div className="app-login-form">
              <form onSubmit={this.handleSubmit}>
                <fieldset>
                <TextField
                    type="text"
                    label={<IntlMessages id="CNPJ" />}
                    fullWidth
                    onChange={(event) => this.setState({ cnpj: event.target.value })}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    required
                    inputProps={{ maxLength: 14}}
                  />
                  <TextField
                    type="email"
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={(event) => this.setState({ email: event.target.value })}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    required
                    inputProps={{ maxLength: 100}}
                  />

                  <TextField
                    type={this.state.showPassword ? 'text' : 'password'}
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) => this.setState({ password: event.target.value })}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    required
                    inputProps={{ maxLength: 50}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button type="submit" variant="contained" color="primary">
                      Entrar
                    </Button>
                  </div>

                </fieldset>
              </form>
            </div>
          </div>

        </div>
        {
          loader &&
          <div className="loader-view">
            <CircularProgress />
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
})(SignIn);