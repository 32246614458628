import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    SIGNIN_USER,
    SIGNOUT_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "actions/Auth";

function encodeURI(params) {
    var esc = encodeURIComponent;
    var query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

    return query;
}

const signInUserWithEmailPasswordRequest = async (email, password,cnpj) => {
    try{
        var opts = {
            email: email,
            password: password,
            cnpj: cnpj
        };
    
        const response = await fetch('https://api-sites-sb.bndv.com.br/api/Account/Token', {
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: encodeURI(opts)
        });

        if (!response.ok) {
            if (response.status === 404)
                throw new Error("Usuário/Senha inválidos");
            if (response.status === 400)
                throw new Error("Ocorreu um erro ao tentar realizar o login");
        }
        else {
            const json = await response.json();
            var obj = {
                user: {
                    name: json.nome,
                    email: json.email,
                    token: json.token
                }
            }
            return obj;
        }
    }
    catch(err){
        throw err.message;
    }
}

function* signInUserWithEmailPassword({payload}) {
    const {email, password,cnpj} = payload;
    try {
        const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password,cnpj);
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            localStorage.setItem('user_id', JSON.stringify(signInUser.user));
            yield put(userSignInSuccess(JSON.stringify(signInUser.user)));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        localStorage.removeItem('user_id');
        yield put(userSignOutSuccess(signOutUser));
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([fork(signInUser),
        fork(signOutUser)]);
}