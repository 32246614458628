import { EditorState } from 'draft-js';
import {
    REQUEST_SITE_EMPRESA_ACTION,
    PUT_SITE_EMPRESA_ACTION,
    RESPONSE_SITE_EMPRESA_ACTION,
    SUCCESS_SITE_EMPRESA_ACTION,
    ERROR_SITE_EMPRESA_ACTION,
    SITE_EMPRESA_SET_DOMAIN_ACTION,
    SITE_EMPRESA_SET_FANTASY_NAME_ACTION,
    SITE_EMPRESA_SET_PHONE_ACTION,
    SITE_EMPRESA_SET_EMAIL_DEFAULT_ACTION,
    SITE_EMPRESA_SET_ADDRESS_ACTION,
    SITE_EMPRESA_SET_CITY_ACTION,
    SITE_EMPRESA_SET_UF_ACTION,
    SITE_EMPRESA_SET_POSTAL_CODE_ACTION,
    SITE_EMPRESA_SET_EMAIL_CONTACT_CONTACT,
    SITE_EMPRESA_SET_EMAIL_CONTACT_PROPOSAL,
    SITE_EMPRESA_SET_EMAIL_CONTACT_FINANCING,
    SITE_EMPRESA_SET_EMAIL_CONTACT_SAFE,
    SITE_EMPRESA_SET_EMAIL_CONTACT_VEHICLEPURCHASE,
    SITE_EMPRESA_SET_ABOUT_ABOUT,
    SITE_EMPRESA_SET_ABOUT_MISSION,
    SITE_EMPRESA_SET_ABOUT_VISION,
    SITE_EMPRESA_SET_ABOUT_VALUES,
    SITE_EMPRESA_SET_SOCIAL_MIDIAS_FACEBOOK,
    SITE_EMPRESA_SET_SOCIAL_MIDIAS_INSTAGRAM,
    SITE_EMPRESA_SET_SOCIAL_MIDIAS_YOUTUBE,
    SITE_EMPRESA_SET_SEO_GOOGLE_ANALYTICS_ID,
    SITE_EMPRESA_SET_SEO_FACEBOOK_ID,
    SITE_EMPRESA_SET_SEO_FACEBOOK_PIXEL_ID,
    REQUEST_SITE_EMPRESA_LIST_CONTACTS_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_CONTACTS_ACTION,
    ERROR_SITE_EMPRESA_LIST_CONTACTS_ACTION,
    REQUEST_SITE_EMPRESA_LIST_HOURS_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_HOURS_ACTION,
    ERROR_SITE_EMPRESA_LIST_HOURS_ACTION,
    REQUEST_SITE_EMPRESA_LIST_BRANCHES_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_BRANCHES_ACTION,
    ERROR_SITE_EMPRESA_LIST_BRANCHES_ACTION,
    SITE_EMPRESA_OPEN_MODAL_CONTACTS,
    SITE_EMPRESA_CLOSE_MODAL_CONTACTS,
    SITE_EMPRESA_SET_CONTACT_TYPE_CONTACT,
    SITE_EMPRESA_SET_CONTACT_TITLE,
    SITE_EMPRESA_SET_CONTACT_VALUE,
    POST_SITE_EMPRESA_CONTACT_ACTION,
    SUCCESS_SITE_EMPRESA_CONTACT_ACTION,
    SITE_EMPRESA_OPEN_MODAL_HOURS,
    SITE_EMPRESA_CLOSE_MODAL_HOURS,
    SITE_EMPRESA_SET_HOUR_NAME,
    SITE_EMPRESA_SET_HOUR_OPEN,
    SITE_EMPRESA_SET_HOUR_CLOSE,
    POST_SITE_EMPRESA_HOUR_ACTION,
    SUCCESS_SITE_EMPRESA_HOUR_ACTION,
    SITE_EMPRESA_OPEN_MODAL_BRANCHES,
    SITE_EMPRESA_CLOSE_MODAL_BRANCHES,
    SITE_EMPRESA_SET_BRANCHES_NAME,
    SITE_EMPRESA_SET_BRANCHES_ADDRESS,
    SITE_EMPRESA_SET_BRANCHES_POSTAL_CODE,
    SITE_EMPRESA_SET_BRANCHES_CITY,
    SITE_EMPRESA_SET_BRANCHES_UF,
    SITE_EMPRESA_SET_BRANCHES_EMAIL,
    SITE_EMPRESA_SET_BRANCHES_PHONE,
    POST_SITE_EMPRESA_BRANCH_ACTION,
    SUCCESS_SITE_EMPRESA_BRANCH_ACTION,
    DELETE_SITE_EMPRESA_CONTACT_ACTION,
    DELETE_SITE_EMPRESA_HOUR_ACTION,
    DELETE_SITE_EMPRESA_BRANCH_ACTION,
    POST_SITE_EMPRESA_LOGO_ACTION,
    SUCCESS_SITE_EMPRESA_LOGO_ACTION,
    ERROR_SITE_EMPRESA_LOGO_ACTION,
    REQUEST_SITE_EMPRESA_TEMA_ACTION,
    RESPONSE_SITE_EMPRESA_TEMA_ACTION,
    SUCCESS_SITE_EMPRESA_TEMA_ACTION,
    ERROR_SITE_EMPRESA_TEMA_ACTION,
    SITE_EMPRESA_THEME_SET_FONT_TYPE,
    SITE_EMPRESA_THEME_SET_BACKGROUND_COLOR,
    SITE_EMPRESA_THEME_SET_PRIMARY_COLOR,
    SITE_EMPRESA_THEME_SET_SECUNDARY_COLOR,
    SITE_EMPRESA_THEME_SET_THEME,
    PUT_SITE_EMPRESA_TEMA_ACTION,
    REQUEST_SITE_EMPRESA_LIST_BANNER_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_BANNER_ACTION,
    ERROR_SITE_EMPRESA_LIST_BANNER_ACTION,
    DELETE_SITE_EMPRESA_LIST_BANNER_ACTION,
    POST_SITE_EMPRESA_LIST_BANNER_ACTION,
    SITE_EMPRESA_BANNER_SET_DELETE_ID,
    SITE_EMPRESA_BANNER_SET_INSERT_ID,
    POST_SITE_EMPRESA_BANNER_UPLOAD_ACTION,
    REQUEST_SITE_EMPRESA_DASHBOARD_ACTION,
    RESPONSE_SITE_EMPRESA_DASHBOARD_ACTION,
    ERROR_SITE_EMPRESA_DASHBOARD_ACTION,
    REQUEST_SITE_EMPRESA_LIST_KEYWORDS_ACTION,
    SITE_EMPRESA_OPEN_MODAL_KEYWORDS,
    SITE_EMPRESA_CLOSE_MODAL_KEYWORDS,
    SITE_EMPRESA_SET_KEYWORDS_TYPE_SCREEN,
    SITE_EMPRESA_SET_KEYWORDS_TITLE,
    SITE_EMPRESA_SET_KEYWORDS_METAKEYWORDS,
    SITE_EMPRESA_SET_KEYWORDS_METADESCRIPTION,
    PUT_SITE_EMPRESA_CONFIGURATION_ACTION,
    SUCCESS_SITE_EMPRESA_CONFIGURACAO_ACTION,
    ERROR_SITE_EMPRESA_CONFIGURACAO_ACTION,
    REQUEST_SITE_EMPRESA_CONFIGURATION_ACTION,
    RESPONSE_SITE_EMPRESA_CONFIGURATION_ACTION,
    SITE_EMPRESA_SET_CONFIGURATION_ORDERTYPE,
    SITE_EMPRESA_SET_CONFIGURATION_INSURANCE,
    SITE_EMPRESA_SET_CONFIGURATION_FINANCING,
    SITE_EMPRESA_SET_CONFIGURATION_GOOGLEVERIFICATIONCODE,    
    POST_SITE_EMPRESA_METAKEYWORDS_ACTION,
    SUCCESS_SITE_EMPRESA_METAKEYWORDS_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_KEYWORDS_ACTION,
    DELETE_SITE_EMPRESA_KEYWORDS_ACTION,
    SITE_EMPRESA_CLOSE_MODAL_KEYWORDS_DELETECONFIRM,
    SITE_EMPRESA_OPEN_MODAL_KEYWORDS_DELETECONFIRM,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_COLOR_THEME,
    REQUEST_SITE_EMPRESA_PLUGINS_ACTION,
    RESPONSE_SITE_EMPRESA_PLUGINS_ACTION,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_OUT_GREETING,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_IN_GREETING,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_PAGE_ID,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_ATIVO,
    PUT_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION,
    SUCCESS_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION,
    ERROR_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION,
    PUT_SITE_EMPRESABANNER_BANNER_ACTION,
    SITE_EMPRESA_SET_CONFIGURATION_FACEBOOKDOMAINVERIFICATIONCODE,
    SITE_EMPRESA_SET_CONFIGURATION_VEHICLEPURCHASE,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ATIVO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ENDERECO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_NUMERO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_COMPLEMENTO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_BAIRRO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CIDADE,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_UF,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CEP,
    PUT_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION,
    SUCCESS_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION,
    ERROR_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION,
    SITE_EMPRESA_SET_CONFIGURATION_GOOGLETAGMANAGER,
    SITE_EMPRESA_SET_CONFIGURATION_TAGBIPCARROS,
    SITE_EMPRESA_PLUGINS_SET_WHATSAPP_ATIVO,
    SITE_EMPRESA_PLUGINS_SET_WHATSAPP_POSICIONAMENTO,
    SITE_EMPRESA_PLUGINS_SET_WHATSAPP_NUMERO,

} from "constants/ActionTypes";

const INIT_STATE = {
    isLoading: false,
    companyLogo: {
        isLoading: false,
        base64Image: ''
    },
    company: {
        id: 0,
        cnpj: '',
        razaoSocial: '',
        nomeFantasia: '',
        endereco: '',
        cidade: '',
        uf: '',
        cep: '',
        email: '',
        telefone: '',
        logo: '',
        dominio: '',
    },
    companyEmailsContact: {
        idEmpresa: 0,
        contato: '',
        proposta: '',
        financiamento: '',
        seguro: '',
        compraVeiculos:'',
    },
    companySocialMidias: {
        idEmpresa: 0,
        facebook: '',
        instagram: '',
        youtube: '',
    },
    companySeo: {
        idEmpresa: 0,
        facebookId: '',
        facebookPixel: '',
        googleAnalyticsId: '',
    },
    companyAbout: {
        idEmpresa: 0,
        missao: EditorState.createEmpty(),
        slogan: EditorState.createEmpty(),
        sobre: EditorState.createEmpty(),
        valores: EditorState.createEmpty(),
        visao: EditorState.createEmpty(),
    },
    companyContacts: {
        isLoading: false,
        errorMessage: '',
        showErrorMessage: false,
        showModal: false,
        deleteId: 0,
        post: {
            idTipoContato: 0,
            titulo: '',
            valor: '',
        },
        results: []
    },
    companyHours: {
        isLoading: false,
        errorMessage: '',
        showErrorMessage: false,
        showModal: false,
        deleteId: 0,
        post: {
            nome: '',
            horaAbertura: '',
            horaEncerramento: '',
        },
        results: []
    },
    companyBranches: {
        isLoading: false,
        errorMessage: '',
        showErrorMessage: false,
        showModal: false,
        deleteId: 0,
        post: {
            nome: '',
            endereco: '',
            cep: '',
            cidade: '',
            uf: '',
            email: '',
            telefone: '',
        },
        results: []
    },
    companyTheme: {
        corFundo: false,
        corPrimaria: '',
        corSecundaria: '',
        idEmpresa: 0,
        idTema: 0,
        idTipoFonte: 0,       
    },
    companyBanners: {
        deleteId: 0,
        insertId: 0,
        base64Image: '',
        results: []
    },
    companyDashboard: {
        isLoading: false,
        errorMessage: '',
        showErrorMessage: false,
        data: {
            qtdeAcessos7Dias: 0,
            qtdePropostas7Dias: 0,
            qtdeVeiculosAnunciados: 0,
            visitasPorDia: [],
            propostasPorDia: [],
            paginasMaisAcessadas: null
        }
    },
    companyConfiguration: {       
        idTipoOrdenacaoEstoque: 0,
        mostrarSeguro: false,
        mostrarFinanciamento:false,
        mostrarCompraVeiculo:false,
        codigoVerificacaoGoogle:null,
        codigoVerificacaoDominioFacebook:null,
        habilitarTagBipCarros:false,
        codigoGoogleTagManager:null,
    },
    companyKeywords: {
        isLoading: false,
        errorMessage: '',
        showErrorMessage: false,
        showModal: false,
        showDeleteConfirmModal:false,
        deleteId: 0,
        post: {            
            title: '',
            metaKeywords: '',
            metaDescription: '',
            idTipoTela: 0            
        },
        results: []
    },
    companyPlugins: {
        isLoading: false,
        errorMessage: '',
        showErrorMessage: false,  
        facebookMessenger: {  
            page_id:'',
            logged_in_greeting: '',
            logged_out_greeting: '',
            theme_color: '',
            ativo: false            
     },
    googleMaps: {
        endereco:'',
        numero:'',
        complemento:'',
        bairro:'',
        cidade:'',
        uf:'',
        cep:'',
        ativo: false
    },        
    whatsapp: {      
        numero:'', 
        posicionamento:'',      
        ativo: false
     }             
    },

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REQUEST_SITE_EMPRESA_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false
            }
        }
        case PUT_SITE_EMPRESA_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false
            }
        }
        case RESPONSE_SITE_EMPRESA_ACTION: {
            return {
                ...state,
                isLoading: false,
                company: action.company,
                companyEmailsContact: action.companyEmailsContact,
                companySocialMidias: action.companySocialMidias,
                companySeo: action.companySeo,
                companyAbout: action.companyAbout,
            }
        }
        case SUCCESS_SITE_EMPRESA_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ERROR_SITE_EMPRESA_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        }
        case SITE_EMPRESA_SET_DOMAIN_ACTION: {
            return {
                ...state,
                company: {
                    ...state.company,
                    dominio: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_FANTASY_NAME_ACTION: {
            return {
                ...state,
                company: {
                    ...state.company,
                    nomeFantasia: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_PHONE_ACTION: {
            return {
                ...state,
                company: {
                    ...state.company,
                    telefone: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_EMAIL_DEFAULT_ACTION: {
            return {
                ...state,
                company: {
                    ...state.company,
                    email: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_ADDRESS_ACTION: {
            return {
                ...state,
                company: {
                    ...state.company,
                    endereco: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_CITY_ACTION: {
            return {
                ...state,
                company: {
                    ...state.company,
                    cidade: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_UF_ACTION: {
            return {
                ...state,
                company: {
                    ...state.company,
                    uf: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_POSTAL_CODE_ACTION: {
            return {
                ...state,
                company: {
                    ...state.company,
                    cep: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_EMAIL_CONTACT_CONTACT: {
            return {
                ...state,
                companyEmailsContact: {
                    ...state.companyEmailsContact,
                    contato: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_EMAIL_CONTACT_PROPOSAL: {
            return {
                ...state,
                companyEmailsContact: {
                    ...state.companyEmailsContact,
                    proposta: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_EMAIL_CONTACT_FINANCING: {
            return {
                ...state,
                companyEmailsContact: {
                    ...state.companyEmailsContact,
                    financiamento: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_EMAIL_CONTACT_SAFE: {
            return {
                ...state,
                companyEmailsContact: {
                    ...state.companyEmailsContact,
                    seguro: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_EMAIL_CONTACT_VEHICLEPURCHASE: {
            return {
                ...state,
                companyEmailsContact: {
                    ...state.companyEmailsContact,
                    compraVeiculos: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_ABOUT_ABOUT: {
            return {
                ...state,
                companyAbout: {
                    ...state.companyAbout,
                    sobre: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_ABOUT_MISSION: {
            return {
                ...state,
                companyAbout: {
                    ...state.companyAbout,
                    missao: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_ABOUT_VISION: {
            return {
                ...state,
                companyAbout: {
                    ...state.companyAbout,
                    visao: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_ABOUT_VALUES: {
            return {
                ...state,
                companyAbout: {
                    ...state.companyAbout,
                    valores: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_SOCIAL_MIDIAS_FACEBOOK: {
            return {
                ...state,
                companySocialMidias: {
                    ...state.companySocialMidias,
                    facebook: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_SOCIAL_MIDIAS_INSTAGRAM: {
            return {
                ...state,
                companySocialMidias: {
                    ...state.companySocialMidias,
                    instagram: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_SOCIAL_MIDIAS_YOUTUBE: {
            return {
                ...state,
                companySocialMidias: {
                    ...state.companySocialMidias,
                    youtube: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_SEO_GOOGLE_ANALYTICS_ID: {
            return {
                ...state,
                companySeo: {
                    ...state.companySeo,
                    googleAnalyticsId: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_SEO_FACEBOOK_ID: {
            return {
                ...state,
                companySeo: {
                    ...state.companySeo,
                    facebookId: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_SEO_FACEBOOK_PIXEL_ID: {
            return {
                ...state,
                companySeo: {
                    ...state.companySeo,
                    facebookPixel: action.payload
                }
            }
        }
        case REQUEST_SITE_EMPRESA_LIST_CONTACTS_ACTION: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                    results: []
                }
            }
        }
        case RESPONSE_SITE_EMPRESA_LIST_CONTACTS_ACTION: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    isLoading: false,
                    results: action.payload
                }
            }
        }
        case ERROR_SITE_EMPRESA_LIST_CONTACTS_ACTION: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    isLoading: false,
                    errorMessage: action.errorMessage,
                    showErrorMessage: true
                }
            }
        }
        case REQUEST_SITE_EMPRESA_LIST_HOURS_ACTION: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                    results: []
                }
            }
        }
        case RESPONSE_SITE_EMPRESA_LIST_HOURS_ACTION: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    isLoading: false,
                    results: action.payload
                }
            }
        }
        case ERROR_SITE_EMPRESA_LIST_HOURS_ACTION: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    isLoading: false,
                    errorMessage: action.errorMessage,
                    showErrorMessage: true
                }
            }
        }
        case REQUEST_SITE_EMPRESA_LIST_BRANCHES_ACTION: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                    results: []
                }
            }
        }
        case RESPONSE_SITE_EMPRESA_LIST_BRANCHES_ACTION: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    isLoading: false,
                    results: action.payload
                }
            }
        }
        case ERROR_SITE_EMPRESA_LIST_BRANCHES_ACTION: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    isLoading: false,
                    errorMessage: action.errorMessage,
                    showErrorMessage: true
                }
            }
        }
        case SITE_EMPRESA_OPEN_MODAL_CONTACTS: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    showModal: true,
                }
            }
        }
        case SITE_EMPRESA_CLOSE_MODAL_CONTACTS: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    showModal: false,
                }
            }
        }
        case SITE_EMPRESA_SET_CONTACT_TYPE_CONTACT: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    post: {
                        ...state.companyContacts.post,
                        idTipoContato: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_CONTACT_TITLE: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    post: {
                        ...state.companyContacts.post,
                        titulo: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_CONTACT_VALUE: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    post: {
                        ...state.companyContacts.post,
                        valor: action.payload
                    }
                }
            }
        }
        case POST_SITE_EMPRESA_CONTACT_ACTION: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                }
            }
        }
        case SUCCESS_SITE_EMPRESA_CONTACT_ACTION: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    isLoading: false,
                    showModal: false,
                    post: {
                        ...state.companyContacts.post,
                        idTipoContato: 0,
                        titulo: '',
                        valor: '',
                    }
                }
            }
        }
        case SITE_EMPRESA_OPEN_MODAL_HOURS: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    showModal: true,
                }
            }
        }
        case SITE_EMPRESA_CLOSE_MODAL_HOURS: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    showModal: false,
                }
            }
        }
        case SITE_EMPRESA_SET_HOUR_NAME: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    post: {
                        ...state.companyHours.post,
                        nome: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_HOUR_OPEN: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    post: {
                        ...state.companyHours.post,
                        horaAbertura: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_HOUR_CLOSE: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    post: {
                        ...state.companyHours.post,
                        horaEncerramento: action.payload
                    }
                }
            }
        }
        case POST_SITE_EMPRESA_HOUR_ACTION: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                }
            }
        }
        case SUCCESS_SITE_EMPRESA_HOUR_ACTION: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    isLoading: false,
                    showModal: false,
                    post: {
                        ...state.companyHours.post,
                        nome: '',
                        horaAbertura: '',
                        horaEncerramento: '',
                    }
                }
            }
        }
        case SITE_EMPRESA_OPEN_MODAL_BRANCHES: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    showModal: true,
                }
            }
        }
        case SITE_EMPRESA_CLOSE_MODAL_BRANCHES: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    showModal: false,
                }
            }
        }
        case SITE_EMPRESA_SET_BRANCHES_NAME: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    post: {
                        ...state.companyBranches.post,
                        nome: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_BRANCHES_ADDRESS: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    post: {
                        ...state.companyBranches.post,
                        endereco: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_BRANCHES_POSTAL_CODE: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    post: {
                        ...state.companyBranches.post,
                        cep: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_BRANCHES_CITY: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    post: {
                        ...state.companyBranches.post,
                        cidade: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_BRANCHES_UF: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    post: {
                        ...state.companyBranches.post,
                        uf: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_BRANCHES_EMAIL: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    post: {
                        ...state.companyBranches.post,
                        email: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_BRANCHES_PHONE: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    post: {
                        ...state.companyBranches.post,
                        telefone: action.payload
                    }
                }
            }
        }
        case POST_SITE_EMPRESA_BRANCH_ACTION: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                }
            }
        }
        case SUCCESS_SITE_EMPRESA_BRANCH_ACTION: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    isLoading: false,
                    showModal: false,
                    post: {
                        ...state.companyBranches.post,
                        nome: '',
                        endereco: '',
                        cep: '',
                        cidade: '',
                        uf: '',
                        email: '',
                        telefone: '',
                    }
                }
            }
        }
        case DELETE_SITE_EMPRESA_CONTACT_ACTION: {
            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                    deleteId: action.payload,
                }
            }
        }
        case DELETE_SITE_EMPRESA_HOUR_ACTION: {
            return {
                ...state,
                companyHours: {
                    ...state.companyHours,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                    deleteId: action.payload,
                }
            }
        }
        case DELETE_SITE_EMPRESA_BRANCH_ACTION: {
            return {
                ...state,
                companyBranches: {
                    ...state.companyBranches,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                    deleteId: action.payload,
                }
            }
        }
        case POST_SITE_EMPRESA_LOGO_ACTION: {
            return {
                ...state,
                companyLogo: {
                    ...state.companyLogo,
                    base64Image: action.payload,
                    isLoading: true,
                }
            }
        }
        case SUCCESS_SITE_EMPRESA_LOGO_ACTION: {
            return {
                ...state,
                companyLogo: {
                    ...state.companyLogo,
                    isLoading: false
                }
            }
        }
        case ERROR_SITE_EMPRESA_LOGO_ACTION: {
            return {
                ...state,
                companyLogo: {
                    ...state.companyLogo,
                    isLoading: false
                }
            }
        }
        case REQUEST_SITE_EMPRESA_TEMA_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false,
            }
        }
        case RESPONSE_SITE_EMPRESA_TEMA_ACTION: {
            return {
                ...state,
                isLoading: false,
                companyTheme: action.companyTheme,
            }
        }
        case SUCCESS_SITE_EMPRESA_TEMA_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ERROR_SITE_EMPRESA_TEMA_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        }
        case SITE_EMPRESA_THEME_SET_FONT_TYPE: {
            return {
                ...state,
                companyTheme: {
                    ...state.companyTheme,
                    idTipoFonte: action.payload
                }
            }
        }
        case SITE_EMPRESA_THEME_SET_BACKGROUND_COLOR: {
            return {
                ...state,
                companyTheme: {
                    ...state.companyTheme,
                    corFundo: action.payload
                }
            }
        }
        case SITE_EMPRESA_THEME_SET_PRIMARY_COLOR: {
            return {
                ...state,
                companyTheme: {
                    ...state.companyTheme,
                    corPrimaria: action.payload
                }
            }
        }
        case SITE_EMPRESA_THEME_SET_SECUNDARY_COLOR: {
            return {
                ...state,
                companyTheme: {
                    ...state.companyTheme,
                    corSecundaria: action.payload
                }
            }
        }
        case SITE_EMPRESA_THEME_SET_THEME: {
            return {
                ...state,
                companyTheme: {
                    ...state.companyTheme,
                    idTema: action.payload
                }
            }
        }
        case PUT_SITE_EMPRESA_TEMA_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false,
            }
        }
        case REQUEST_SITE_EMPRESA_LIST_BANNER_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false,
                companyBanners: {
                    ...state.companyBanners,
                    results: []
                }
            }
        }
        case RESPONSE_SITE_EMPRESA_LIST_BANNER_ACTION: {
            return {
                ...state,
                isLoading: false,
                companyBanners: {
                    ...state.companyBanners,
                    results: action.payload
                }
            }
        }
        case ERROR_SITE_EMPRESA_LIST_BANNER_ACTION: {
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload,
                showErrorMessage: true,
            }
        }
        case SITE_EMPRESA_BANNER_SET_DELETE_ID: {
            return {
                ...state,
                companyBanners: {
                    ...state.companyBanners,
                    deleteId: action.payload
                }
            }
        }
        case SITE_EMPRESA_BANNER_SET_INSERT_ID: {
            return {
                ...state,
                companyBanners: {
                    ...state.companyBanners,
                    insertId: action.payload
                }
            }
        }
        case DELETE_SITE_EMPRESA_LIST_BANNER_ACTION: {
            return { 
                ...state,
                isLoading: true
            }
        }
        case POST_SITE_EMPRESA_LIST_BANNER_ACTION: {
            return {
                ...state,
                isLoading: true
            }
        }
        case POST_SITE_EMPRESA_BANNER_UPLOAD_ACTION: {
            return {
                ...state,
                isLoading: true,
                companyBanners: {
                    ...state.companyBanners,
                    base64Image: action.payload
                }
            }
        }
        case REQUEST_SITE_EMPRESA_DASHBOARD_ACTION: {
            return {
                ...state,
                companyDashboard: {
                    ...state.companyDashboard,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false
                }
            }
        }
        case RESPONSE_SITE_EMPRESA_DASHBOARD_ACTION: {
            return {
                ...state,
                companyDashboard: {
                    ...state.companyDashboard,
                    isLoading: false,
                    data: action.payload
                }
            }
        }
        case ERROR_SITE_EMPRESA_DASHBOARD_ACTION: {
            return {
                ...state,
                companyDashboard: {
                    ...state.companyDashboard,
                    isLoading: false,
                    errorMessage: action.payload,
                    showErrorMessage: true
                }
            }
        }
        case REQUEST_SITE_EMPRESA_LIST_KEYWORDS_ACTION: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                    results: []
                }
            }
        }
        case RESPONSE_SITE_EMPRESA_LIST_KEYWORDS_ACTION: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    isLoading: false,
                    results: action.payload
                }
            }
        }
        case SITE_EMPRESA_OPEN_MODAL_KEYWORDS: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    showModal: true,
                }
            }
        }
        case SITE_EMPRESA_CLOSE_MODAL_KEYWORDS: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    showModal: false,
                }
            }
        }
        case SITE_EMPRESA_SET_KEYWORDS_TYPE_SCREEN: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    post: {
                        ...state.companyKeywords.post,
                        idTipoTela: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_KEYWORDS_TITLE: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    post: {
                        ...state.companyKeywords.post,
                        title: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_KEYWORDS_METAKEYWORDS: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    post: {
                        ...state.companyKeywords.post,
                        metaKeywords: action.payload
                    }
                }
            }
        }
        case SITE_EMPRESA_SET_KEYWORDS_METADESCRIPTION: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    post: {
                        ...state.companyKeywords.post,
                        metaDescription: action.payload
                    }
                }
            }
        }
        case PUT_SITE_EMPRESA_CONFIGURATION_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false,
            }
        }
        case SUCCESS_SITE_EMPRESA_CONFIGURACAO_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ERROR_SITE_EMPRESA_CONFIGURACAO_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        }
        case REQUEST_SITE_EMPRESA_CONFIGURATION_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false,
            }
        }
        case RESPONSE_SITE_EMPRESA_CONFIGURATION_ACTION: {
            return {
                ...state,
                isLoading: false,
                companyConfiguration: action.companyConfiguration,
            }
        }
        case SITE_EMPRESA_SET_CONFIGURATION_ORDERTYPE: {
            return {
                ...state,
                companyConfiguration: {
                    ...state.companyConfiguration,
                    idTipoOrdenacaoEstoque: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_CONFIGURATION_FINANCING: {
            return {
                ...state,
                companyConfiguration: {
                    ...state.companyConfiguration,
                   mostrarFinanciamento: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_CONFIGURATION_GOOGLEVERIFICATIONCODE: {
            return {
                ...state,
                companyConfiguration: {
                    ...state.companyConfiguration,
                    codigoVerificacaoGoogle: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_CONFIGURATION_FACEBOOKDOMAINVERIFICATIONCODE: {
            return {
                ...state,
                companyConfiguration: {
                    ...state.companyConfiguration,
                    codigoVerificacaoDominioFacebook: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_CONFIGURATION_INSURANCE: {
            return {
                ...state,
                companyConfiguration: {
                    ...state.companyConfiguration,
                   mostrarSeguro: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_CONFIGURATION_VEHICLEPURCHASE: {
            return {
                ...state,
                companyConfiguration: {
                    ...state.companyConfiguration,
                   mostrarCompraVeiculo: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_CONFIGURATION_TAGBIPCARROS: {
            return {
                ...state,
                companyConfiguration: {
                    ...state.companyConfiguration,
                    habilitarTagBipCarros: action.payload
                }
            }
        }
        case SITE_EMPRESA_SET_CONFIGURATION_GOOGLETAGMANAGER: {
            return {
                ...state,
                companyConfiguration: {
                    ...state.companyConfiguration,
                    codigoGoogleTagManager: action.payload
                }
            }
        }
        case POST_SITE_EMPRESA_METAKEYWORDS_ACTION: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false,
                }
            }
        }
        case SUCCESS_SITE_EMPRESA_METAKEYWORDS_ACTION: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    isLoading: false,
                    showModal: false,
                    post: {
                        ...state.companyKeywords.post,                       
                        title: '',
                        metaKeywords: '',
                        metaDescription: '',
                        idTipoTela: 0
                        
                    }
                }
            }
        }
        case DELETE_SITE_EMPRESA_KEYWORDS_ACTION: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    isLoading: true,
                    errorMessage: '',
                    showErrorMessage: false, 
                    showDeleteConfirmModal:false,
                }
            }
        }
        case SITE_EMPRESA_CLOSE_MODAL_KEYWORDS_DELETECONFIRM: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    showDeleteConfirmModal: false,
                }
            }
        }
        case SITE_EMPRESA_OPEN_MODAL_KEYWORDS_DELETECONFIRM: {
            return {
                ...state,
                companyKeywords: {
                    ...state.companyKeywords,
                    showDeleteConfirmModal: true,
                    deleteId: action.payload,
                }
            }
        }
        case SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_COLOR_THEME: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    facebookMessenger: {
                        ...state.companyPlugins.facebookMessenger,
                        theme_color: action.payload
                    }                  
                }
            }
        }
        case REQUEST_SITE_EMPRESA_PLUGINS_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false,
            }
        }
        case RESPONSE_SITE_EMPRESA_PLUGINS_ACTION: {
            return {
                ...state,
                isLoading: false,              
                companyPlugins: {
                    ...state.companyPlugins.facebookMessenger,
                    facebookMessenger: action.companyPlugins.facebookMessenger,
                    ...state.companyPlugins.googleMaps,
                    googleMaps: action.companyPlugins.googleMaps,
                    ...state.companyPlugins.whatsapp,
                    whatsapp: action.companyPlugins.whatsapp
                }

            }
        }
        case SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_OUT_GREETING: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    facebookMessenger: {
                        ...state.companyPlugins.facebookMessenger,
                        logged_out_greeting: action.payload
                    }                  
                }
            }
        }
        case SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_IN_GREETING: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    facebookMessenger: {
                        ...state.companyPlugins.facebookMessenger,
                        logged_in_greeting: action.payload
                    }                  
                }
            }
        }  
        case SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_PAGE_ID: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    facebookMessenger: {
                        ...state.companyPlugins.facebookMessenger,
                        page_id: action.payload
                    }                  
                }
            }
        } 
        case SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_ATIVO: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    facebookMessenger: {
                        ...state.companyPlugins.facebookMessenger,
                        ativo: action.payload
                    }                  
                }
            }
        }  
        case PUT_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false,
            }
        }
        case SUCCESS_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ERROR_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        } 
        case PUT_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION: {
            return {
                ...state,
                isLoading: true,
                errorMessage: '',
                showErrorMessage: false,
            }
        }
        case SUCCESS_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ERROR_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION: {
            return {
                ...state,
                isLoading: false
            }
        } 
        case SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ATIVO: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    googleMaps: {
                        ...state.companyPlugins.googleMaps,
                        ativo: action.payload
                    }                  
                }
            }
        }  
        case SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_NUMERO: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    googleMaps: {
                        ...state.companyPlugins.googleMaps,
                        numero: action.payload
                    }                  
                }
            }
        }  
        case SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ENDERECO: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    googleMaps: {
                        ...state.companyPlugins.googleMaps,
                        endereco: action.payload
                    }                  
                }
            }
        } 
        case SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_COMPLEMENTO: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    googleMaps: {
                        ...state.companyPlugins.googleMaps,
                        complemento: action.payload
                    }                  
                }
            }
        } 
        case SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_BAIRRO: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    googleMaps: {
                        ...state.companyPlugins.googleMaps,
                        bairro: action.payload
                    }                  
                }
            }
        } 
        case SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CIDADE: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    googleMaps: {
                        ...state.companyPlugins.googleMaps,
                        cidade: action.payload
                    }                  
                }
            }
        } 
        case SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_UF: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    googleMaps: {
                        ...state.companyPlugins.googleMaps,
                        uf: action.payload
                    }                  
                }
            }
        } 
        case SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CEP: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    googleMaps: {
                        ...state.companyPlugins.googleMaps,
                        cep: action.payload
                    }                  
                }
            }
        } 
        case SITE_EMPRESA_PLUGINS_SET_WHATSAPP_ATIVO: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    whatsapp: {
                        ...state.companyPlugins.whatsapp,
                        ativo: action.payload
                    }                  
                }
            }
        }  
        case SITE_EMPRESA_PLUGINS_SET_WHATSAPP_NUMERO: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    whatsapp: {
                        ...state.companyPlugins.whatsapp,
                        numero: action.payload
                    }                  
                }
            }
        }  
        case SITE_EMPRESA_PLUGINS_SET_WHATSAPP_POSICIONAMENTO: {
            return {
                ...state,
                companyPlugins: {
                    ...state.companyPlugins,
                    whatsapp: {
                        ...state.companyPlugins.whatsapp,
                        posicionamento: action.payload
                    }                  
                }
            }
        } 
        case PUT_SITE_EMPRESABANNER_BANNER_ACTION: {
            return {
                ...state,               
                errorMessage: '',
                showErrorMessage: false,
            }
        }       
        default:
            return state;
    }
}
