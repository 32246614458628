import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import siteEmpresaSagas from './site/Empresa';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        siteEmpresaSagas()
    ]);
}
