import { NotificationManager } from 'react-notifications';
import {
    REQUEST_SITE_EMPRESA_ACTION,
    PUT_SITE_EMPRESA_ACTION,
    RESPONSE_SITE_EMPRESA_ACTION,
    SUCCESS_SITE_EMPRESA_ACTION,
    ERROR_SITE_EMPRESA_ACTION,
    SITE_EMPRESA_SET_DOMAIN_ACTION,
    SITE_EMPRESA_SET_FANTASY_NAME_ACTION,
    SITE_EMPRESA_SET_PHONE_ACTION,
    SITE_EMPRESA_SET_EMAIL_DEFAULT_ACTION,
    SITE_EMPRESA_SET_ADDRESS_ACTION,
    SITE_EMPRESA_SET_CITY_ACTION,
    SITE_EMPRESA_SET_UF_ACTION,
    SITE_EMPRESA_SET_POSTAL_CODE_ACTION,
    SITE_EMPRESA_SET_EMAIL_CONTACT_CONTACT,
    SITE_EMPRESA_SET_EMAIL_CONTACT_PROPOSAL,
    SITE_EMPRESA_SET_EMAIL_CONTACT_FINANCING,
    SITE_EMPRESA_SET_EMAIL_CONTACT_SAFE,
    SITE_EMPRESA_SET_ABOUT_ABOUT,
    SITE_EMPRESA_SET_ABOUT_MISSION,
    SITE_EMPRESA_SET_ABOUT_VISION,
    SITE_EMPRESA_SET_ABOUT_VALUES,
    SITE_EMPRESA_SET_SOCIAL_MIDIAS_FACEBOOK,
    SITE_EMPRESA_SET_SOCIAL_MIDIAS_INSTAGRAM,
    SITE_EMPRESA_SET_SOCIAL_MIDIAS_YOUTUBE,
    SITE_EMPRESA_SET_SEO_GOOGLE_ANALYTICS_ID,
    SITE_EMPRESA_SET_SEO_FACEBOOK_ID,
    SITE_EMPRESA_SET_SEO_FACEBOOK_PIXEL_ID,
    REQUEST_SITE_EMPRESA_LIST_CONTACTS_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_CONTACTS_ACTION,
    REQUEST_SITE_EMPRESA_LIST_HOURS_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_HOURS_ACTION,
    REQUEST_SITE_EMPRESA_LIST_BRANCHES_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_BRANCHES_ACTION,
    SITE_EMPRESA_OPEN_MODAL_CONTACTS,
    SITE_EMPRESA_CLOSE_MODAL_CONTACTS,
    SITE_EMPRESA_SET_CONTACT_TYPE_CONTACT,
    SITE_EMPRESA_SET_CONTACT_TITLE,
    SITE_EMPRESA_SET_CONTACT_VALUE,
    POST_SITE_EMPRESA_CONTACT_ACTION,
    SUCCESS_SITE_EMPRESA_CONTACT_ACTION,
    SITE_EMPRESA_OPEN_MODAL_HOURS,
    SITE_EMPRESA_CLOSE_MODAL_HOURS,
    SITE_EMPRESA_SET_HOUR_NAME,
    SITE_EMPRESA_SET_HOUR_OPEN,
    SITE_EMPRESA_SET_HOUR_CLOSE,
    POST_SITE_EMPRESA_HOUR_ACTION,
    SUCCESS_SITE_EMPRESA_HOUR_ACTION,
    SITE_EMPRESA_OPEN_MODAL_BRANCHES,
    SITE_EMPRESA_CLOSE_MODAL_BRANCHES,
    SITE_EMPRESA_SET_BRANCHES_NAME,
    SITE_EMPRESA_SET_BRANCHES_ADDRESS,
    SITE_EMPRESA_SET_BRANCHES_POSTAL_CODE,
    SITE_EMPRESA_SET_BRANCHES_CITY,
    SITE_EMPRESA_SET_BRANCHES_UF,
    SITE_EMPRESA_SET_BRANCHES_EMAIL,
    SITE_EMPRESA_SET_BRANCHES_PHONE,
    POST_SITE_EMPRESA_BRANCH_ACTION,
    SUCCESS_SITE_EMPRESA_BRANCH_ACTION,
    DELETE_SITE_EMPRESA_CONTACT_ACTION,
    DELETE_SITE_EMPRESA_HOUR_ACTION,
    DELETE_SITE_EMPRESA_BRANCH_ACTION,
    POST_SITE_EMPRESA_LOGO_ACTION,
    SUCCESS_SITE_EMPRESA_LOGO_ACTION,
    ERROR_SITE_EMPRESA_LOGO_ACTION,
    REQUEST_SITE_EMPRESA_TEMA_ACTION,
    RESPONSE_SITE_EMPRESA_TEMA_ACTION,
    SITE_EMPRESA_THEME_SET_FONT_TYPE,
    SITE_EMPRESA_THEME_SET_BACKGROUND_COLOR,
    SITE_EMPRESA_THEME_SET_PRIMARY_COLOR,
    SITE_EMPRESA_THEME_SET_SECUNDARY_COLOR,
    SITE_EMPRESA_THEME_SET_THEME,
    PUT_SITE_EMPRESA_TEMA_ACTION,
    SUCCESS_SITE_EMPRESA_TEMA_ACTION,
    ERROR_SITE_EMPRESA_TEMA_ACTION,
    REQUEST_SITE_EMPRESA_LIST_BANNER_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_BANNER_ACTION,
    DELETE_SITE_EMPRESA_LIST_BANNER_ACTION,
    POST_SITE_EMPRESA_LIST_BANNER_ACTION,
    SITE_EMPRESA_BANNER_SET_DELETE_ID,
    SITE_EMPRESA_BANNER_SET_INSERT_ID,
    POST_SITE_EMPRESA_BANNER_UPLOAD_ACTION,
    REQUEST_SITE_EMPRESA_DASHBOARD_ACTION,
    RESPONSE_SITE_EMPRESA_DASHBOARD_ACTION,
    ERROR_SITE_EMPRESA_DASHBOARD_ACTION,
    REQUEST_SITE_EMPRESA_LIST_KEYWORDS_ACTION,
    RESPONSE_SITE_EMPRESA_LIST_KEYWORDS_ACTION,  
    SITE_EMPRESA_OPEN_MODAL_KEYWORDS,
    SITE_EMPRESA_CLOSE_MODAL_KEYWORDS,
    SITE_EMPRESA_SET_KEYWORDS_TYPE_SCREEN,
    SITE_EMPRESA_SET_KEYWORDS_TITLE,
    SITE_EMPRESA_SET_KEYWORDS_METAKEYWORDS,
    SITE_EMPRESA_SET_KEYWORDS_METADESCRIPTION,
    PUT_SITE_EMPRESA_CONFIGURATION_ACTION,
    SUCCESS_SITE_EMPRESA_CONFIGURACAO_ACTION,
    ERROR_SITE_EMPRESA_CONFIGURACAO_ACTION,
    REQUEST_SITE_EMPRESA_CONFIGURATION_ACTION,
    RESPONSE_SITE_EMPRESA_CONFIGURATION_ACTION,
    SITE_EMPRESA_SET_CONFIGURATION_ORDERTYPE,
    SITE_EMPRESA_SET_CONFIGURATION_INSURANCE,
    SITE_EMPRESA_SET_CONFIGURATION_FINANCING,
    SITE_EMPRESA_SET_CONFIGURATION_GOOGLEVERIFICATIONCODE,
    SITE_EMPRESA_SET_CONFIGURATION_FACEBOOKDOMAINVERIFICATIONCODE,
    POST_SITE_EMPRESA_METAKEYWORDS_ACTION,
    SUCCESS_SITE_EMPRESA_METAKEYWORDS_ACTION,
    DELETE_SITE_EMPRESA_KEYWORDS_ACTION,
    SITE_EMPRESA_CLOSE_MODAL_KEYWORDS_DELETECONFIRM,
    SITE_EMPRESA_OPEN_MODAL_KEYWORDS_DELETECONFIRM,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_COLOR_THEME,
    REQUEST_SITE_EMPRESA_PLUGINS_ACTION,
    RESPONSE_SITE_EMPRESA_PLUGINS_ACTION,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_OUT_GREETING,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_IN_GREETING,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_PAGE_ID,
    SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_ATIVO,
    PUT_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION,
    SUCCESS_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION,
    ERROR_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION,
    PUT_SITE_EMPRESABANNER_BANNER_ACTION,
    SITE_EMPRESA_SET_CONFIGURATION_VEHICLEPURCHASE,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ATIVO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ENDERECO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_NUMERO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_COMPLEMENTO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_BAIRRO,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CIDADE,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_UF,
    SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CEP,
    PUT_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION,
    SUCCESS_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION,
    ERROR_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION,
    SITE_EMPRESA_SET_EMAIL_CONTACT_VEHICLEPURCHASE,
    SITE_EMPRESA_SET_CONFIGURATION_TAGBIPCARROS,
    SITE_EMPRESA_SET_CONFIGURATION_GOOGLETAGMANAGER,
    SITE_EMPRESA_PLUGINS_SET_WHATSAPP_ATIVO,
    SITE_EMPRESA_PLUGINS_SET_WHATSAPP_POSICIONAMENTO,
    SITE_EMPRESA_PLUGINS_SET_WHATSAPP_NUMERO,
    PUT_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION,
    SUCCESS_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION,
    ERROR_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION,

} from 'constants/ActionTypes';


export const fnGetCompany = () => {
    return { type: REQUEST_SITE_EMPRESA_ACTION }
}

export const fnUpdateCompany = () => {
    return { type: PUT_SITE_EMPRESA_ACTION }
}

export const fnGetCompanyResponse = (
    company,
    companyEmailsContact,
    companySocialMidias,
    companySeo,
    companyAbout) => {
    return {
        type: RESPONSE_SITE_EMPRESA_ACTION,
        company: company,
        companyEmailsContact: companyEmailsContact,
        companySocialMidias: companySocialMidias,
        companySeo: companySeo,
        companyAbout: companyAbout,
    }
}

export const fnUpdateCompanySuccess = () => {
    NotificationManager.success("Dados atualizados com sucesso");
    return { type: SUCCESS_SITE_EMPRESA_ACTION }
}

export const fnUpdateCompanyError = () => {
    NotificationManager.error("Erro ao atualizar dados da empresa");
    return { type: ERROR_SITE_EMPRESA_ACTION }
}

export const fnSetCompanyDomain = (data) => {
    return {
        type: SITE_EMPRESA_SET_DOMAIN_ACTION,
        payload: data
    }
}

export const fnSetCompanyFantasyName = (data) => {
    return {
        type: SITE_EMPRESA_SET_FANTASY_NAME_ACTION,
        payload: data
    }
}

export const fnSetCompanyPhone = (data) => {
    return {
        type: SITE_EMPRESA_SET_PHONE_ACTION,
        payload: data
    }
}

export const fnSetCompanyEmail = (data) => {
    return {
        type: SITE_EMPRESA_SET_EMAIL_DEFAULT_ACTION,
        payload: data
    }
}

export const fnSetCompanyAddress = (data) => {
    return {
        type: SITE_EMPRESA_SET_ADDRESS_ACTION,
        payload: data
    }
}

export const fnSetCompanyCity = (data) => {
    return {
        type: SITE_EMPRESA_SET_CITY_ACTION,
        payload: data
    }
}

export const fnSetCompanyUf = (data) => {
    return {
        type: SITE_EMPRESA_SET_UF_ACTION,
        payload: data
    }
}

export const fnSetCompanyPostalCode = (data) => {
    return {
        type: SITE_EMPRESA_SET_POSTAL_CODE_ACTION,
        payload: data
    }
}

export const fnSetCompanyEmailsContactContact = (data) => {
    return {
        type: SITE_EMPRESA_SET_EMAIL_CONTACT_CONTACT,
        payload: data
    }
}

export const fnSetCompanyEmailsContactProposal = (data) => {
    return {
        type: SITE_EMPRESA_SET_EMAIL_CONTACT_PROPOSAL,
        payload: data
    }
}

export const fnSetCompanyEmailsContactFinancing = (data) => {
    return {
        type: SITE_EMPRESA_SET_EMAIL_CONTACT_FINANCING,
        payload: data
    }
}

export const fnSetCompanyEmailsContactSafe = (data) => {
    return {
        type: SITE_EMPRESA_SET_EMAIL_CONTACT_SAFE,
        payload: data
    }
}

export const fnSetCompanyEmailsContactVehiclePurchase = (data) => {
    return {
        type: SITE_EMPRESA_SET_EMAIL_CONTACT_VEHICLEPURCHASE,
        payload: data
    }
}

export const fnSetCompanyAboutAbout = (data) => {
    return {
        type: SITE_EMPRESA_SET_ABOUT_ABOUT,
        payload: data
    }
}

export const fnSetCompanyAboutMission = (data) => {
    return {
        type: SITE_EMPRESA_SET_ABOUT_MISSION,
        payload: data
    }
}

export const fnSetCompanyAboutVision = (data) => {
    return {
        type: SITE_EMPRESA_SET_ABOUT_VISION,
        payload: data
    }
}

export const fnSetCompanyAboutValues = (data) => {
    return {
        type: SITE_EMPRESA_SET_ABOUT_VALUES,
        payload: data
    }
}

export const fnSetCompanySocialMidiasFacebook = (data) => {
    return {
        type: SITE_EMPRESA_SET_SOCIAL_MIDIAS_FACEBOOK,
        payload: data
    }
}

export const fnSetCompanySocialMidiasInstagram = (data) => {
    return {
        type: SITE_EMPRESA_SET_SOCIAL_MIDIAS_INSTAGRAM,
        payload: data
    }
}

export const fnSetCompanySocialMidiasYoutube = (data) => {
    return {
        type: SITE_EMPRESA_SET_SOCIAL_MIDIAS_YOUTUBE,
        payload: data
    }
}

export const fnSetCompanySeoGoogleAnalyticsId = (data) => {
    return {
        type: SITE_EMPRESA_SET_SEO_GOOGLE_ANALYTICS_ID,
        payload: data
    }
}

export const fnSetCompanySeoFacebookId = (data) => {
    return {
        type: SITE_EMPRESA_SET_SEO_FACEBOOK_ID,
        payload: data
    }
}

export const fnSetCompanySeoFacebookPixelId = (data) => {
    return {
        type: SITE_EMPRESA_SET_SEO_FACEBOOK_PIXEL_ID,
        payload: data
    }
}

export const fnGetCompanyContacts = () => {
    return { type: REQUEST_SITE_EMPRESA_LIST_CONTACTS_ACTION }
}

export const fnGetCompanyContactsSuccess = (data) => {
    return {
        type: RESPONSE_SITE_EMPRESA_LIST_CONTACTS_ACTION,
        payload: data
    }
}

export const fnGetCompanyHours = () => {
    return { type: REQUEST_SITE_EMPRESA_LIST_HOURS_ACTION }
}

export const fnGetCompanyHoursSuccess = (data) => {
    return {
        type: RESPONSE_SITE_EMPRESA_LIST_HOURS_ACTION,
        payload: data
    }
}

export const fnGetCompanyBranches = () => {
    return { type: REQUEST_SITE_EMPRESA_LIST_BRANCHES_ACTION }
}

export const fnGetCompanyBranchesSuccess = (data) => {
    return {
        type: RESPONSE_SITE_EMPRESA_LIST_BRANCHES_ACTION,
        payload: data
    }
}

export const fnOpenModalContacts = () => {
    return { type: SITE_EMPRESA_OPEN_MODAL_CONTACTS }
}

export const fnCloseModalContacts = () => {
    return { type: SITE_EMPRESA_CLOSE_MODAL_CONTACTS }
}

export const fnSetCompanyContactTypeContact = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONTACT_TYPE_CONTACT,
        payload: data
    }
}

export const fnSetCompanyContactTitle = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONTACT_TITLE,
        payload: data
    }
}

export const fnSetCompanyContactValue = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONTACT_VALUE,
        payload: data
    }
}

export const fnInsertCompanyContact = () => {
    return { type: POST_SITE_EMPRESA_CONTACT_ACTION }
}

export const fnInsertCompanyContactSuccess = () => {
    return {
        type: SUCCESS_SITE_EMPRESA_CONTACT_ACTION
    }
}

export const fnOpenModalHours = () => {
    return { type: SITE_EMPRESA_OPEN_MODAL_HOURS }
}

export const fnCloseModalHours = () => {
    return { type: SITE_EMPRESA_CLOSE_MODAL_HOURS }
}

export const fnSetCompanyHourName = (data) => {
    return {
        type: SITE_EMPRESA_SET_HOUR_NAME,
        payload: data
    }
}

export const fnSetCompanyHourOpen = (data) => {
    return {
        type: SITE_EMPRESA_SET_HOUR_OPEN,
        payload: data
    }
}

export const fnSetCompanyHourClose = (data) => {
    return {
        type: SITE_EMPRESA_SET_HOUR_CLOSE,
        payload: data
    }
}

export const fnInsertCompanyHour = () => {
    return { type: POST_SITE_EMPRESA_HOUR_ACTION }
}

export const fnInsertCompanyHourSuccess = () => {
    return {
        type: SUCCESS_SITE_EMPRESA_HOUR_ACTION
    }
}


export const fnOpenModalBranches = () => {
    return { type: SITE_EMPRESA_OPEN_MODAL_BRANCHES }
}

export const fnCloseModalBranches = () => {
    return { type: SITE_EMPRESA_CLOSE_MODAL_BRANCHES }
}

export const fnSetCompanyBranchesName = (data) => {
    return {
        type: SITE_EMPRESA_SET_BRANCHES_NAME,
        payload: data
    }
}

export const fnSetCompanyBranchesAddress = (data) => {
    return {
        type: SITE_EMPRESA_SET_BRANCHES_ADDRESS,
        payload: data
    }
}

export const fnSetCompanyBranchesPostalCode = (data) => {
    return {
        type: SITE_EMPRESA_SET_BRANCHES_POSTAL_CODE,
        payload: data
    }
}

export const fnSetCompanyBranchesCity = (data) => {
    return {
        type: SITE_EMPRESA_SET_BRANCHES_CITY,
        payload: data
    }
}

export const fnSetCompanyBranchesUf = (data) => {
    return {
        type: SITE_EMPRESA_SET_BRANCHES_UF,
        payload: data
    }
}

export const fnSetCompanyBranchesEmail = (data) => {
    return {
        type: SITE_EMPRESA_SET_BRANCHES_EMAIL,
        payload: data
    }
}

export const fnSetCompanyBranchesPhone = (data) => {
    return {
        type: SITE_EMPRESA_SET_BRANCHES_PHONE,
        payload: data
    }
}

export const fnInsertCompanyBranch = () => {
    return { type: POST_SITE_EMPRESA_BRANCH_ACTION }
}

export const fnInsertCompanyBranchSuccess = () => {
    return { type: SUCCESS_SITE_EMPRESA_BRANCH_ACTION }
}

export const fnDeleteCompanyContact = (id) => {
    return {
        type: DELETE_SITE_EMPRESA_CONTACT_ACTION,
        payload: id
    }
}

export const fnDeleteCompanyHour = (id) => {
    return {
        type: DELETE_SITE_EMPRESA_HOUR_ACTION,
        payload: id
    }
}

export const fnDeleteCompanyBranch = (id) => {
    return {
        type: DELETE_SITE_EMPRESA_BRANCH_ACTION,
        payload: id
    }
}

export const fnInsertCompanyLogo = (logo) => {
    return {
        type: POST_SITE_EMPRESA_LOGO_ACTION,
        payload: logo
    }
}

export const fnInsertCompanyLogoSuccess = () => {
    NotificationManager.success("Logotipo atualizado com sucesso");
    return { type: SUCCESS_SITE_EMPRESA_LOGO_ACTION }
}

export const fnInsertCompanyLogoError = () => {
    NotificationManager.error("Erro ao fazer upload do logotipo, tente novamente!");
    return { type: ERROR_SITE_EMPRESA_LOGO_ACTION }
}

export const fnGetCompanyTheme = () => {
    return { type: REQUEST_SITE_EMPRESA_TEMA_ACTION }
}
 
export const fnGetCompanyThemeSuccess = (companyTheme) => {
    return {
        type: RESPONSE_SITE_EMPRESA_TEMA_ACTION,
        companyTheme: companyTheme
    }
}

export const fnSetCompanyThemeFontType = (data) => {
    return {
        type: SITE_EMPRESA_THEME_SET_FONT_TYPE,
        payload: data
    }
}

export const fnSetCompanyThemeBackgroundColor = (data) => {
    return {
        type: SITE_EMPRESA_THEME_SET_BACKGROUND_COLOR,
        payload: data
    }
}

export const fnSetCompanyThemePrimaryColor = (data) => {
    return {
        type: SITE_EMPRESA_THEME_SET_PRIMARY_COLOR,
        payload: data
    }
}

export const fnSetCompanyThemeSecundaryColor = (data) => {
    return {
        type: SITE_EMPRESA_THEME_SET_SECUNDARY_COLOR,
        payload: data
    }
}

export const fnSetCompanyTheme = (data) => {
    return {
        type: SITE_EMPRESA_THEME_SET_THEME,
        payload: data
    }
}

export const fnUpdateCompanyTheme = () => {
    return { type: PUT_SITE_EMPRESA_TEMA_ACTION }
}

export const fnUpdateCompanyThemeSuccess = () => {
    NotificationManager.success("Dados do tema atualizados com sucesso");
    return { type: SUCCESS_SITE_EMPRESA_TEMA_ACTION }
}

export const fnUpdateCompanyThemeError = () => {
    NotificationManager.error("Erro ao atualizar informações, tente novamente");
    return { type: ERROR_SITE_EMPRESA_TEMA_ACTION }
}

export const fnGetCompanyBanners = () => {
    return { type: REQUEST_SITE_EMPRESA_LIST_BANNER_ACTION }
}

export const fnGetCompanyBannersSuccess = (data) => {
    return {
        type: RESPONSE_SITE_EMPRESA_LIST_BANNER_ACTION,
        payload: data
    }
}

export const fnSetCompanyBannerDeleteId = (data) => {
    return {
        type: SITE_EMPRESA_BANNER_SET_DELETE_ID,
        payload: data
    }
}

export const fnSetCompanyBannerInsertId = (data) => {
    return {
        type: SITE_EMPRESA_BANNER_SET_INSERT_ID,
        payload: data
    }
}

export const fnDeleteCompanyBanner = () => {
    return { type: DELETE_SITE_EMPRESA_LIST_BANNER_ACTION }
}

export const fnInsertCompanyBanner = () => {
    return { type: POST_SITE_EMPRESA_LIST_BANNER_ACTION }
}

export const fnUploadCompanyBanner = (banner) => {
    return {
        type: POST_SITE_EMPRESA_BANNER_UPLOAD_ACTION,
        payload: banner,
    }
}

export const fnGetCompanyDashboard = () => {
    return { type: REQUEST_SITE_EMPRESA_DASHBOARD_ACTION }
}

export const fnGetCompanyDashboardResponse = (data) => {
    return { 
        type: RESPONSE_SITE_EMPRESA_DASHBOARD_ACTION,
        payload: data
    }
}

export const fnGetCompanyDashboardError = (error) => {
    return { 
        type: ERROR_SITE_EMPRESA_DASHBOARD_ACTION,
        payload: error
     }
}

export const fnGetCompanyKeywords = () => {
    return { type: REQUEST_SITE_EMPRESA_LIST_KEYWORDS_ACTION }
}
export const fnGetCompanyKeywordsSuccess = (data) => {
    return { type: RESPONSE_SITE_EMPRESA_LIST_KEYWORDS_ACTION,
            payload: data }
}

export const fnOpenModalKeywords = () => {
    return { type: SITE_EMPRESA_OPEN_MODAL_KEYWORDS }
}

export const fnCloseModalKeywords = () => {
    return { type: SITE_EMPRESA_CLOSE_MODAL_KEYWORDS }
}
export const fnSetCompanyKeywordsTypeScreen = (data) => {
    return {
        type: SITE_EMPRESA_SET_KEYWORDS_TYPE_SCREEN,
        payload: data
    }
}
export const fnSetCompanyKeywordsTitle = (data) => {
    return {
        type: SITE_EMPRESA_SET_KEYWORDS_TITLE,
        payload: data
    }
}
export const fnSetCompanyKeywordsMetaKeywords = (data) => {
    return {
        type: SITE_EMPRESA_SET_KEYWORDS_METAKEYWORDS,
        payload: data
    }
}
export const fnSetCompanyKeywordsMetaDescription = (data) => {
    return {
        type: SITE_EMPRESA_SET_KEYWORDS_METADESCRIPTION,
        payload: data
    }
}

export const fnUpdateCompanyConfiguration = () => {
    return { type: PUT_SITE_EMPRESA_CONFIGURATION_ACTION }
}

export const fnUpdateCompanyConfigurationSuccess = () => {
    NotificationManager.success("Configurações atualizadas com sucesso");
    return { type: SUCCESS_SITE_EMPRESA_CONFIGURACAO_ACTION }
}

export const fnUpdateCompanyConfigurationError = () => {
    NotificationManager.error("Erro ao atualizar informações, tente novamente");
    return { type: ERROR_SITE_EMPRESA_CONFIGURACAO_ACTION }
}

export const fnGetCompanyConfiguration = () => {
    return { type: REQUEST_SITE_EMPRESA_CONFIGURATION_ACTION }
}
 
export const fnGetCompanyConfigurationSuccess = (companyConfiguration) => {
    return {
        type: RESPONSE_SITE_EMPRESA_CONFIGURATION_ACTION,
        companyConfiguration: companyConfiguration
    }
}

export const fnSetCompanyConfigurationOrderType = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONFIGURATION_ORDERTYPE,
        payload: data
    }
}
export const fnSetCompanyConfigurationShowInsurance = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONFIGURATION_INSURANCE,
        payload: data
    }
}
export const fnSetCompanyConfigurationShowFinancing = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONFIGURATION_FINANCING,
        payload: data
    }
}
export const fnSetCompanyConfigurationShowVehiclePurchase = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONFIGURATION_VEHICLEPURCHASE,
        payload: data
    }
}
export const fnInsertCompanyMetakeywords = () => {
    return { type: POST_SITE_EMPRESA_METAKEYWORDS_ACTION }
}

export const fnInsertCompanyMetakeywordsSuccess = () => {
    return {
        type: SUCCESS_SITE_EMPRESA_METAKEYWORDS_ACTION
    }
}
export const fnDeleteCompanyKeywords = () => {
    return {
        type: DELETE_SITE_EMPRESA_KEYWORDS_ACTION      
    }
}
export const fnCloseKeywordsDeleteConfirmModal = () => {
    return { type: SITE_EMPRESA_CLOSE_MODAL_KEYWORDS_DELETECONFIRM }
}
export const fnOpenKeywordsDeleteConfirmModal = (id) => {
    return { type: SITE_EMPRESA_OPEN_MODAL_KEYWORDS_DELETECONFIRM,
    payload: id
 }

}

export const fnSetPluginFacebookMenssengerThemeColor = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_COLOR_THEME,
        payload: data
    }
}
export const fnGetCompanyPlugins = () => {
    return { type: REQUEST_SITE_EMPRESA_PLUGINS_ACTION }
}
export const fnGetCompanyPluginsSuccess = (companyPlugins) => {
    return {
        type: RESPONSE_SITE_EMPRESA_PLUGINS_ACTION,
        companyPlugins: companyPlugins
    }
}
export const fnSetPluginFacebookMessengerOutGreeting = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_OUT_GREETING,
        payload: data
    }
}
export const fnSetPluginFacebookMessengerInGreeting = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_IN_GREETING,
        payload: data
    }
}
export const fnSetPluginFacebookMessengerPageId = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_PAGE_ID,
        payload: data
    }
}
export const fnSetPluginFacebookMessengerAtivo = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_FACEBOOKMENSSENGER_ATIVO,
        payload: data
    }
}

export const fnUpdateCompanyPluginFacebookMessenger = () => {
    return { type: PUT_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION }
}

export const fnUpdateCompanyPluginFacebookMessengerSuccess = () => {
    NotificationManager.success("Plugin atualizado com sucesso.");
    return { type: SUCCESS_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION }
}

export const fnUpdateCompanyPluginFacebookMessengerError = () => {
    NotificationManager.error("Erro ao atualizar plugin do Facebook, tente novamente.");
    return { type: ERROR_SITE_EMPRESA_PLUGIN_FACEBOOKMESSENGER_ACTION }
}
export const fnUpdateCompanyPluginGoogleMaps = () => {
    return { type: PUT_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION }
}

export const fnUpdateCompanyPluginGoogleMapsSuccess = () => {
    NotificationManager.success("Plugin atualizado com sucesso.");
    return { type: SUCCESS_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION }
}

export const fnUpdateCompanyPluginGoogleMapsError = () => {
    NotificationManager.error("Erro ao atualizar plugin do Google Maps, tente novamente.");
    return { type: ERROR_SITE_EMPRESA_PLUGIN_GOOGLEMAPS_ACTION }
}
export const fnUpdateCompanyBanner = (banner) => {
    return {
        type: PUT_SITE_EMPRESABANNER_BANNER_ACTION,
        payload: banner
    };
}
export const fnSetPluginGoogleMapsAtivo = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ATIVO,
        payload: data
    }
}
export const fnSetPluginGoogleMapsEndereco = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_ENDERECO,
        payload: data
    }
}
export const fnSetPluginGoogleMapsNumero = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_NUMERO,
        payload: data
    }
}
export const fnSetPluginGoogleMapsComplemento = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_COMPLEMENTO,
        payload: data
    }
}
export const fnSetPluginGoogleMapsBairro = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_BAIRRO,
        payload: data
    }
}
export const fnSetPluginGoogleMapsCidade = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CIDADE,
        payload: data
    }
}
export const fnSetPluginGoogleMapsUf = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_UF,
        payload: data
    }
}
export const fnSetPluginGoogleMapsCep = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_GOOGLEMAPS_CEP,
        payload: data
    }
}
export const fnSetCompanyConfigurationGoogleVerificarion = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONFIGURATION_GOOGLEVERIFICATIONCODE,
        payload: data
    }
}
export const fnSetCompanyConfigurationFacebookDomainVerificarion = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONFIGURATION_FACEBOOKDOMAINVERIFICATIONCODE,
        payload: data
    }
};
export const fnSetCompanyConfigurationTagBipCarros = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONFIGURATION_TAGBIPCARROS,
        payload: data
    }
};
export const fnSetCompanyConfigurationGoogleTagManager = (data) => {
    return {
        type: SITE_EMPRESA_SET_CONFIGURATION_GOOGLETAGMANAGER,
        payload: data
    }
};
export const fnSetPluginWhatsappAtivo = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_WHATSAPP_ATIVO,
        payload: data
    }
}
export const fnSetPluginWhatsappPosicionamento = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_WHATSAPP_POSICIONAMENTO,
        payload: data
    }
}
export const fnSetPluginWhatsappNumero = (data) => {
    return {
        type: SITE_EMPRESA_PLUGINS_SET_WHATSAPP_NUMERO,
        payload: data
    }
}
export const fnUpdateCompanyPluginWhatsapp = () => {
    return { type: PUT_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION }
}

export const fnUpdateCompanyPluginWhatsappSuccess = () => {
    NotificationManager.success("Plugin atualizado com sucesso.");
    return { type: SUCCESS_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION }
}

export const fnUpdateCompanyPluginWhatsappError = () => {
    NotificationManager.error("Erro ao atualizar plugin do Whatsapp, tente novamente.");
    return { type: ERROR_SITE_EMPRESA_PLUGIN_WHATSAPP_ACTION }
}